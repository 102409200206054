import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Grid2 as Grid } from '@mui/material';
import { InfoTableWidgetType } from '../../Enums';
import NumberField from '../formfields/NumberField';
import PercentageField from '../formfields/PercentageField';
import PieChartHC, { IPieChartData } from './PieChartHC';

export interface IInfoData {
  key: string;
  value: any;
}

interface InfoTableWidgetProps {
  type: InfoTableWidgetType;
  title: string;
  data?: IInfoData[];
  showValues: boolean;
  showPieChart: boolean;
  children?: React.ReactNode;
}

export default function InfoTableWidget(props: InfoTableWidgetProps) {
  const colors = [
    '#de996f',
    '#92928f',
    '#876191',
    '#4073a5',
    '#58723f',
    '#af4a42',
    '#ebc2a9',
    '#bebebc',
    '#b89fbf',
    '#86abd0',
    '#9bb87e',
    '#d38f8a',
  ];

  const renderValue = (type: InfoTableWidgetType, value: any) => {
    switch (type) {
      case InfoTableWidgetType.Number:
        return <NumberField className="row-text2" value={value} />;
      case InfoTableWidgetType.Percentage:
        return <PercentageField className="row-text2" value={value} />;
      case InfoTableWidgetType.Text:
        return <Typography className="row-text2">{value}</Typography>;
      default:
        return <></>;
    }
  };

  return (
    <Box className="info-table-widet">
      <Grid container>
        <Grid size={{ xs: 12 }} paddingTop={1}>
          <Typography variant="h5">{props.title}</Typography>
        </Grid>
        <Grid
          size={{ xs: 12 }}
          paddingTop={1}
          justifyItems="center"
          borderBottom={'1px solid'}
        ></Grid>
        {props.showPieChart && props.data && props.data.length > 0 ? (
          <Grid size={{ xs: 12 }}>
            <PieChartHC
              data={props.data
                ?.filter((f) => f.value > 0)
                .map((ad) => ({ name: ad.key, y: ad.value } as IPieChartData))}
            />
          </Grid>
        ) : (
          <></>
        )}
        {props.showValues ? (
          <>
            {props.data
              ?.filter((f) => f.value > 0)
              .map((d, index) => {
                return (
                  <Grid
                    size={{ xs: 12 }}
                    padding={1}
                    key={d.key}
                    className="rows"
                  >
                    <Grid container>
                      <Grid size={{ xs: 6 }} alignItems={'center'}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box
                            mr={1}
                            width={'10px'}
                            height={'10px'}
                            border={'1px solid'}
                            borderColor={colors[index]}
                            borderRadius={'5px'}
                            sx={{
                              backgroundColor: colors[index],
                              display: 'block',
                            }}
                          >
                            &nbsp;
                          </Box>
                          <Box>
                            <Typography variant="body2">{d.key}</Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        size={{ xs: 6 }}
                        alignContent="right"
                        container
                        justifyContent="flex-end"
                      >
                        {renderValue(props.type, d.value)}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
          </>
        ) : (
          <></>
        )}
        <Box width={'100%'}>{props.children}</Box>
      </Grid>
    </Box>
  );
}
