import KycForm from '../components/forms/KycForm';
import KycFormCompany from '../components/forms/KycCompany';
import { useAppSelector } from '../redux/hooks';
import { getActiveUser } from '../redux/stateFuncs';
import { useReactToPrint } from 'react-to-print';
import { Box, Button } from '@mui/material';
import { useRef } from 'react';

const Kyc = () => {
  const users = useAppSelector((state) => state.users);

  const activeUser = getActiveUser(users);
  const contentRef = useRef<HTMLDivElement>(null);
  const reactToPrintFn = useReactToPrint({ contentRef });

  return (
    <>
      <div ref={contentRef}>
        {activeUser?.isCompany == true ? <KycFormCompany /> : <KycForm />}
      </div>
      <Box m={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => reactToPrintFn}
        >
          Print
        </Button>
      </Box>
    </>
  );
};

export default Kyc;
