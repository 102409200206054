import { Grid2 as Grid } from '@mui/material';
import InvestmentLayout from '../../components/layout/InvestmentLayout';

import PortfolioDocuments from '../../components/widgets/PortfolioDocuments';

export default function DocumentsPage() {
  return (
    <>
      <InvestmentLayout>
        <Grid
          container
          rowSpacing={0}
          sx={{ width: '100%', maxWidth: '96em' }}
          justifyContent="flex-start"
        >
          <Grid>
            <PortfolioDocuments />
          </Grid>
        </Grid>
      </InvestmentLayout>
    </>
  );
}
