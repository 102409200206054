import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { useAuth } from 'react-oidc-context';
import PersonIcon from '@mui/icons-material/PersonOutlineRounded';

import { useTranslation } from 'react-i18next';
import i18n from 'i18next';

import se_flag from '../../images/flags/se.png';
import en_flag from '../../images/flags/en.png';
import { getActiveUserName } from '../../redux/stateFuncs';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  clearUsers,
  deactivateUsers,
  setActiveUser,
} from '../../redux/slices/users';
import { useMediaQuery } from '@mui/material';
import { clearPortfolios } from '../../redux/slices/portfolios';
import { setNavigation } from '../../redux/slices/navigation';
import { useCookies } from 'react-cookie';

var logo = require('../../images/coeli_transparent.png');

const TopBar = () => {
  const users = useAppSelector((state) => state.users);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['i18next']);

  const settings = [
    { title: 'SwitchAccount', callback: openUsersMenu },
    { title: 'Logout', callback: Logout },
  ];

  const languages = [
    { flag: se_flag, lang: 'se', desc: 'Svenska' },
    { flag: en_flag, lang: 'en', desc: 'English' },
  ];

  var currentLanguage = i18n.language;

  const auth = useAuth();
  const { t } = useTranslation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUsers, setAnchorElUsers] = React.useState<null | HTMLElement>(
    null
  );

  const [anchorElLang, setAnchorElLang] = React.useState<null | HTMLElement>(
    null
  );

  function openUsersMenu() {
    setAnchorElUsers(anchorElUser);
    setAnchorElUser(null);
  }
  const handleOpenUsersMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUsers(event.currentTarget);
  };

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleOpenLangMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLang(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleCloseUsersMenu = () => {
    setAnchorElUsers(null);
  };

  const handleCloseLangMenu = () => {
    setAnchorElLang(null);
  };

  const changeLanguage = (lang: string) => {
    currentLanguage = lang;
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
      setCookie('i18next', lang);
    }
    setAnchorElLang(null);
  };

  const switchUser = (userId: string) => {
    setAnchorElUsers(null);
    dispatch(clearPortfolios());
    dispatch(setActiveUser(userId));
    dispatch(setNavigation(''));
    navigate('/');
  };

  function Logout() {
    sessionStorage.removeItem('coeli_token');
    dispatch(clearUsers());
    auth.signoutRedirect({ id_token_hint: auth.user?.id_token });
    auth.removeUser();
  }

  const showName = useMediaQuery('(min-width:460px)');

  return (
    <AppBar
      position="fixed"
      color="primary"
      className="app-bar"
      enableColorOnDark
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
      elevation={0}
    >
      <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
        <Toolbar disableGutters variant="dense">
          <Box sx={{ flexGrow: 0 }} marginLeft={0.5}>
            <a href={process.env.REACT_APP_OAUTH_REDIRECT_URI}>
              <img
                src={logo}
                alt="CoeliLogo"
                style={{ width: 32, height: 36 }}
              />
            </a>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box
            visibility={showName ? 'visible' : 'hidden'}
            sx={{ flexGrow: 0, flexShrink: 0 }}
            marginRight={1}
            marginLeft={1}
          >
            <Button
              onClick={handleOpenUsersMenu}
              color="primary"
              variant="outlined"
            >
              {getActiveUserName(users)}
            </Button>
          </Box>
          <Box sx={{ flexGrow: 0 }} marginLeft="8px">
            <Tooltip title={'Change language'}>
              <IconButton onClick={handleOpenLangMenu} sx={{ p: 0 }}>
                <img
                  alt={currentLanguage}
                  style={{ width: 24, height: 24 }}
                  src={languages.find((e) => e.lang == currentLanguage)?.flag}
                ></img>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElLang}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElLang)}
              onClose={handleCloseLangMenu}
            >
              {languages.map((lang) => (
                <MenuItem
                  key={lang.lang}
                  onClick={() => changeLanguage(lang.lang)}
                >
                  <img
                    style={{ width: 32, height: 32, verticalAlign: 'middle' }}
                    src={lang.flag}
                  ></img>
                  <Typography textAlign="center" marginLeft="8px">
                    {lang.desc}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 0 }} marginLeft="8px">
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar
                  sx={{ width: 32, height: 32 }}
                  alt={getActiveUserName(users)}
                >
                  <PersonIcon
                    className="avatar"
                    sx={{ fontSize: 32 }}
                  ></PersonIcon>
                </Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.title} onClick={setting.callback}>
                  <Typography textAlign="center">{t(setting.title)}</Typography>
                </MenuItem>
              ))}
            </Menu>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUsers}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUsers)}
              onClose={handleCloseUsersMenu}
            >
              <MenuItem
                key="seperator1"
                divider={true}
                sx={{
                  opacity: '1.0',
                  paddingLeft: '8px',
                  pointerEvents: 'none',
                  cursor: 'default',
                }}
              >
                <Typography className="top-bar-text">{t('Users')}</Typography>
              </MenuItem>
              {users
                .filter((u) => u.isCompany == false)
                .map((user) => (
                  <MenuItem key={user.id} onClick={() => switchUser(user.id)}>
                    <Typography
                      textAlign="center"
                      className="top-bar-text-names"
                    >
                      {user.name}
                    </Typography>
                  </MenuItem>
                ))}
              <MenuItem
                key="seperator2"
                divider={true}
                sx={{
                  opacity: '1.0',
                  fontWeight: 'bold',
                  paddingLeft: '8px',
                  pointerEvents: 'none',
                  cursor: 'default',
                }}
              >
                <Typography className="top-bar-text">
                  {t('Companies')}
                </Typography>
              </MenuItem>
              {users
                .filter((u) => u.isCompany == true)
                .map((company) => (
                  <MenuItem
                    key={company.name}
                    onClick={() => switchUser(company.id)}
                  >
                    <Typography
                      textAlign="center"
                      className="top-bar-text-names"
                    >
                      {company.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
};
TopBar.whyDidYouRender = true;
export default TopBar;
