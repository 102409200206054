import React, { useEffect, useMemo, useRef, useState } from 'react';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Grid2 as Grid } from '@mui/material';
import {
  DataGridPremium as DataGrid,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-premium';

import {
  Button,
  Divider,
  Typography,
  Box,
  CircularProgress,
  Stack,
  TextField,
  SxProps,
} from '@mui/material';
import {
  OpportunityState,
  ToDoEnum,
  TradeOrderBaseTypeEnum,
  TradeOrderOperationEnum,
} from '../../Enums';
import { Common } from '../../util/common';
import { useApiClient } from '../../hooks/apiClient';
import SecurityDetails, { SecurityInfoSize } from '../SecurityDetails';
import { AxiosInstance } from 'axios';
import { getActiveUser, getMainUser } from '../../redux/stateFuncs';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useTranslation } from 'react-i18next';
import { setToDoComplete } from '../../redux/slices/todo';
import { getOrderTypeNameString } from '../../util/translations';
import CurrencyField from '../formfields/CurrencyField';
import {
  IMultipleTradeOrdersDto,
  ITradeOrderDto,
} from '../../models/dto/tradeOrderDto';
import StringField from '../formfields/StringField';
import { IInitSignResponse } from '../../models/initInsigniaResponse';
import { Widgets } from '@mui/icons-material';

interface ITradeOrderProps {
  unlisted: boolean;
  isToDo: boolean;
}

function TradeOrder(props: ITradeOrderProps) {
  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isQrCodeFetched, setIsQrCodeFetched] = useState(false);
  const [initSignResponse, setInitSignResponse] = useState<IInitSignResponse>();
  const [isSigning, setIsSigning] = useState(false);
  const [isSigned, setIsSigned] = useState(false);
  const [unlisted, setisUnlisted] = useState(
    props.unlisted != undefined ? props.unlisted : false
  );
  const [apiError, setApiError] = React.useState({
    isOpen: false,
    Error: '',
    InnerError: '',
  });

  var isChecking = false;
  const isToDo: boolean | undefined = props.isToDo;

  // const unlisted: boolean =
  //   props.unlisted != undefined ? props.unlisted : false;

  const activeUserCanSign = getActiveUser(users)?.canSign;

  const [isFetched, setIsFetched] = useState(false);

  const [opportunityTradeorders, setOpportunityTradeorders] = useState<
    IMultipleTradeOrdersDto[]
  >([]);

  const handleClickSign = (id: string) => {
    setIsQrCodeFetched(false);

    var activeUser = getActiveUser(users);
    var activeEntityId = activeUser?.id;
    var entityType = activeUser?.isCompany == true ? 'account' : 'contact';

    const mainUserSSN = getMainUser(users)?.socialSecurityNumber;
    const mainUserId = getMainUser(users)?.id;

    let intervalId: number | undefined;
    let counter = 0;
    let initSigningResponse: IInitSignResponse;

    api
      .post('api/Signing/InitSigningUnlistedTradeOrder', {
        socialSecurityNumber: mainUserSSN,
        time: counter,
        opportunityId: id,
        orderRef: '',
      })
      .then((res) => {
        initSigningResponse = res.data as IInitSignResponse;
        setInitSignResponse(initSigningResponse);
        setIsQrCodeFetched(true);
      })
      .then(() => {
        intervalId = window.setInterval(() => {
          if (counter > 20) {
            window.clearInterval(intervalId);
            setIsQrCodeFetched(false);
            api
              .post(`api/Signing/CancelSign/${initSigningResponse.orderRef}`)
              .then((res) => {
                setIsSigning(false);
              });
            setApiError({
              ...apiError,
              isOpen: true,
              Error: 'Signing Error',
              InnerError:
                'The allowed time for signing the document has been exceeded. To repeat the operation, click on the confirm button.',
            });
          } else {
            if (!isChecking) {
              isChecking = true;
              counter++;

              initSigningResponse.time = counter;
              initSigningResponse.approverId = mainUserId;
              initSigningResponse.entityId = id;
              initSigningResponse.ownerId = activeEntityId;
              initSigningResponse.customerLogicalName = entityType;
              initSigningResponse.entityLogicalName = 'opportunity';
              initSigningResponse.socialSecurityNumber = mainUserSSN;
              initSigningResponse.status = '';

              api
                .post(
                  `api/Signing/GetSigningAndApproveUnlistedTradeOrder`,
                  initSigningResponse
                )
                .then(
                  (res) => {
                    initSigningResponse = res.data as IInitSignResponse;
                    setInitSignResponse(initSigningResponse);
                    if (initSigningResponse.status === 'complete') {
                      window.clearInterval(intervalId);

                      setIsQrCodeFetched(false);
                      setIsSigning(false);
                      setIsSigned(false);

                      // Check if this is a todo popup and if all done, mark to do as complete
                      if (isToDo) {
                        dispatch(setToDoComplete(ToDoEnum.UnlistedTradeOrders));
                      }
                    }

                    isChecking = false;
                  },
                  (err) => {
                    isChecking = false;
                    api.post(
                      `api/Signing/CancelSign/${initSigningResponse.orderRef}`
                    );
                    window.clearInterval(intervalId);
                    setIsQrCodeFetched(false);
                    setIsSigning(false);
                    if (
                      err.response.data.additionalDetails.message !=
                      'InvalidParameters: No such order'
                    )
                      setApiError({
                        ...apiError,
                        isOpen: true,
                        Error: 'Signing Error',
                        InnerError: err.response.data.additionalDetails.message,
                      });
                  }
                );
            }
          }
        }, 2000);
      });
  };

  const handleClickAccept = (id: string, isAccept: boolean) => {
    setIsFetched(false);
    const opportunityTradeorder: IMultipleTradeOrdersDto | undefined =
      opportunityTradeorders.find((p) => p.opportunityId === id);

    var activeUser = getActiveUser(users);
    var activeEntityId = activeUser?.id;
    var entityType = activeUser?.isCompany == true ? 'account' : 'contact';

    const mainUserId = getMainUser(users)?.id;
    let statuscode = isAccept ? 'Won' : 'Canceled';
    api
      .post('api/tradeorder/update', {
        ...opportunityTradeorder,
        statuscode: statuscode,
        approverId: mainUserId,
        ownerId: activeEntityId,
        customerType: entityType,
      })
      .then((res) => {
        const newOpportunityTradeorders = opportunityTradeorders.filter((p) => {
          if (p.opportunityId !== opportunityTradeorder?.opportunityId) {
            return true;
          }
          return false;
        });

        if (newOpportunityTradeorders.length > 0) {
          setOpportunityTradeorders(newOpportunityTradeorders);
          setIsFetched(true);
        } else {
          setIsFetched(true);
          setIsQrCodeFetched(false);
          dispatch(setToDoComplete(ToDoEnum.TradeOrders));
        }
      });
  };

  const handleClickGetAllExante = (orders: ITradeOrderDto[]) => {
    setIsFetched(false);
    let ids = orders.map((p) => p.tradeOrderRequestId).join(',');
    api
      .get(
        `api/DocumentGenerator/ExecutePortfolioTradeorderFlowForMultipleFiles/${ids}`,
        {
          method: 'GET',
          responseType: 'text',
        }
      )
      .then(
        (res) => {
          let result = JSON.parse(res.data);
          if (result.status == 'Running' || result.status == 'Queued') {
            checkFlowResult(result.id);
          } else if (result.status == 'Error') {
            alert(result.message);
            setIsFetched(true);
          } else {
            let documentObject = Common.getObject(result.model, 'document');
            getDocumentContent(documentObject.document.id);
          }
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const handleClickExante = (tradeOrderRequestId: string) => {
    setIsFetched(false);
    api
      .get(
        `api/DocumentGenerator/ExecuteTradeorderFlow/${tradeOrderRequestId}`,
        {
          method: 'GET',
          responseType: 'text',
        }
      )
      .then(
        (res) => {
          let result = JSON.parse(res.data);
          if (result.status == 'Running' || result.status == 'Queued') {
            checkFlowResult(result.id);
          } else if (result.status == 'Error' || result.status == 500) {
            alert(result.message);
            setIsFetched(true);
          } else {
            getDocumentIds(result);
          }
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const getDocumentIds = (result: any) => {
    if (Object.keys(result.model).length > 1) {
      for (var prop in result.model) {
        setIsFetched(false);
        let documentObject = Common.getObject(result.model[prop], 'document');
        getDocumentContent(documentObject.document.id);
      }
    } else {
      let documentObject = Common.getObject(result.model, 'document');
      getDocumentContent(documentObject.document.id);
    }
  };

  const checkFlowResult = (executionId: string) => {
    api
      .get(`api/DocumentGenerator/CheckDocumentExecution/${executionId}`, {
        method: 'GET',
        responseType: 'text',
      })
      .then(
        (res) => {
          let result = JSON.parse(res.data);
          if (result.status == 'Running' || result.status == 'Queued') {
            checkFlowResult(result.id);
          } else if (result.status == 'Error') {
            setIsFetched(true);
          } else {
            getDocumentIds(result);
          }
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const getDocumentContent = (documentId: string) => {
    api
      .get(`api/DocumentGenerator/GetDocumentContent/${documentId}`, {
        method: 'GET',
        responseType: 'text',
      })
      .then(
        (res) => {
          const url = 'data:application/octet-stream;base64,' + res.data;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Exante_${documentId}.pdf`);
          document.body.appendChild(link);
          setIsFetched(true);
          link.click();
        },
        (err) => {
          setIsFetched(true);
        }
      );
  };

  const getTradeUnitTitle = (order: ITradeOrderDto): string => {
    if (
      order.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
      order.tradeOrderType == TradeOrderOperationEnum.SellUnitsS
    ) {
      return t('tradeOrders.TradeAmountUnit');
    }

    return t('tradeOrders.TradeAmount');
  };

  const columnsDeskstop: GridColDef[] = [
    {
      field: 'securityName',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Security'),
      flex: 0.3,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{params.value}</Typography>
      ),
    },
    {
      field: 'tradeOrderType',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeOrderType'),
      flex: 0.12,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <Typography>{t(getOrderTypeNameString(params.value))}</Typography>
      ),
    },
    {
      field: 'amount',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Units'),
      flex: 0.12,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <CurrencyField
          value={params.row.amount}
          decimals={6}
          fixedDecimals={false}
          suffix=""
        ></CurrencyField>
      ),
    },
    {
      field: 'transactionFee',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Fee'),
      flex: 0.115,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) =>
        !unlisted &&
        (params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
          params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS) ? (
          <StringField value={'TBC'}></StringField>
        ) : (
          <CurrencyField value={params.value}></CurrencyField>
        ),
    },
    {
      field: 'tradeAmount',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeAmount'),
      flex: 0.185,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) =>
        !unlisted &&
        (params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
          params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS) ? (
          <CurrencyField
            value={params.row.amount}
            suffix={t('tradeOrders.Units').toLowerCase()}
            decimals={6}
            fixedDecimals={false}
          ></CurrencyField>
        ) : (
          <CurrencyField
            value={params.row.tradeAmount}
            suffix={params.row.currencyCode}
          ></CurrencyField>
        ),
    },
    {
      field: 'isinCode',
      headerClassName: 'trade-order-header',
      headerName: t('Details'),
      flex: unlisted == true ? 0.3 : 0.125,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <SecurityDetails
          isinCode={params.value}
          opportunityId={params.row.opportunityId}
          api={api}
          size={SecurityInfoSize.Large}
          unlisted={unlisted}
        />
      ),
    },
    {
      field: 'tradeOrderRequestId',
      headerClassName: 'trade-order-header',
      headerName: '',
      flex: 0.255,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) =>
        !unlisted ? (
          <Button
            size="small"
            variant="outlined"
            color="primary"
            disabled={
              params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
              params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS
            }
            startIcon={<InsertDriveFileIcon />}
            onClick={() => handleClickExante(params.row.tradeOrderRequestId)}
          >
            {t('Exante')}
          </Button>
        ) : (
          <></>
        ),
    },
  ];

  const columnsDeskstopMedium: GridColDef[] = [
    {
      field: 'securityName',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Security'),
      flex: 0.3,
      display: 'flex',
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.Security')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Typography className="small-dg-text2">{params.value}</Typography>
      ),
    },
    {
      field: 'tradeOrderType',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeOrderType'),
      flex: 0.12,
      display: 'flex',
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.TradeOrderType')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Typography className="small-dg-text2">
          {t(getOrderTypeNameString(params.value))}
        </Typography>
      ),
    },
    {
      field: 'amount',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Units'),
      flex: 0.12,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <CurrencyField
          value={params.row.amount}
          decimals={6}
          fixedDecimals={false}
          className="small-dg-text2"
          suffix=""
        ></CurrencyField>
      ),
    },
    {
      field: 'transactionFee',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Fee'),
      flex: 0.115,
      display: 'flex',
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.Fee')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) =>
        !unlisted &&
        (params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
          params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS) ? (
          <StringField value={'TBC'} className="small-dg-text2"></StringField>
        ) : (
          <CurrencyField
            value={params.value}
            className="small-dg-text2"
          ></CurrencyField>
        ),
    },
    {
      field: 'tradeAmount',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeAmount'),
      flex: 0.185,
      display: 'flex',
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.TradeAmount')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) =>
        !unlisted &&
        (params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
          params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS) ? (
          <CurrencyField
            value={params.row.amount}
            suffix={'andelar'}
            decimals={6}
            fixedDecimals={false}
            className="small-dg-text2"
          ></CurrencyField>
        ) : (
          <CurrencyField
            value={params.row.tradeAmount}
            suffix={params.row.currencyCode}
            className="small-dg-text2"
          ></CurrencyField>
        ),
    },
    {
      field: 'isinCode',
      headerClassName: 'trade-order-header',
      headerName: t('InformationShort'),
      flex: unlisted == true ? 0.3 : 0.125,
      display: 'flex',
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('InformationShort')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <SecurityDetails
          isinCode={params.value}
          opportunityId={params.row.opportunityId}
          api={api}
          size={SecurityInfoSize.Medium}
          unlisted={unlisted}
        />
      ),
    },
    {
      field: 'tradeOrderRequestId',
      headerClassName: 'trade-order-header',
      headerName: '',
      flex: 0.255,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <Button
          size="small"
          variant="outlined"
          color="primary"
          className="small-dg-text2"
          disabled={
            params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
            params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS
          }
          startIcon={<InsertDriveFileIcon />}
          onClick={() => handleClickExante(params.row.tradeOrderRequestId)}
        >
          {t('Exante')}
        </Button>
      ),
    },
  ];

  const columnsDesktopSmall: GridColDef[] = [
    {
      field: 'securityName',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Security'),
      flex: 0.35,
      display: 'flex',
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.Security')}
          </Typography>
          <Typography className="small-dg-header italic">
            {t('tradeOrders.TradeOrderType')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Typography className="small-dg-text2">
            {params.row.securityName}
          </Typography>
          <Typography className="small-dg-text2 italic">
            {t(getOrderTypeNameString(params.row.tradeOrderType))}
          </Typography>
        </Box>
      ),
    },
    {
      field: 'tradeAmount',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeAmount'),
      flex: 0.25,
      display: 'flex',
      renderHeader: () => (
        <Box>
          {/* <!-- -- unlisted --> */}
          <Typography className="small-dg-header">
            {t('tradeOrders.TradeAmount')}
          </Typography>
          <Typography className="small-dg-header italic">
            {t('tradeOrders.Fee')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
          params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS ? (
            <CurrencyField
              value={params.row.amount}
              suffix={'andelar'}
              className="small-dg-text2"
              decimals={6}
              fixedDecimals={false}
            ></CurrencyField>
          ) : (
            <CurrencyField
              value={params.row.tradeAmount}
              suffix={params.row.currencyCode}
              className="small-dg-text2"
            ></CurrencyField>
          )}
          {!unlisted &&
          (params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
            params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS) ? (
            <StringField
              value={'TBC'}
              className="small-dg-text2 italic"
            ></StringField>
          ) : (
            <CurrencyField
              value={params.row.transactionFee}
              className="small-dg-text2 italic"
            ></CurrencyField>
          )}
        </Box>
      ),
    },
    {
      field: 'isinCode',
      headerClassName: 'trade-order-header',
      headerName: t('InformationShort'),
      flex: 0.3,
      display: 'flex',
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('InformationShort')}
          </Typography>
          {unlisted == true ? (
            <>
              <Typography className="small-dg-header">
                {t('tradeOrders.TradeAmount')}
              </Typography>
            </>
          ) : (
            <></>
          )}
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <SecurityDetails
            isinCode={params.value}
            opportunityId={params.row.opportunityId}
            api={api}
            size={SecurityInfoSize.Small}
            unlisted={unlisted}
          />
          {unlisted != true ? (
            <>
              <br></br>
              <Button
                size="small"
                variant="outlined"
                disabled={
                  params.row.tradeOrderType ==
                    TradeOrderOperationEnum.BuyUnitsB ||
                  params.row.tradeOrderType ==
                    TradeOrderOperationEnum.SellUnitsS
                }
                color="primary"
                className="small-dg-text2 small-btn"
                onClick={() =>
                  handleClickExante(params.row.tradeOrderRequestId)
                }
              >
                {t('Exante')}
              </Button>
            </>
          ) : (
            <></>
          )}
        </Box>
      ),
    },
  ];

  const columnsMobile: GridColDef[] = [
    {
      field: 'securityName',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.Security'),
      flex: 0.4,
      display: 'flex',
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.Security')}
          </Typography>
          <Typography className="small-dg-header italic">
            {t('tradeOrders.TradeOrderType')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Typography className="small-dg-text2">
            {params.row.securityName}
          </Typography>
          <Typography className="small-dg-text2 italic">
            {t(getOrderTypeNameString(params.row.tradeOrderType))}
          </Typography>
          {unlisted != true ? (
            <Button
              size="small"
              variant="outlined"
              color="primary"
              className="small-dg-text2 small-btn"
              disabled={
                params.row.tradeOrderType ==
                  TradeOrderOperationEnum.BuyUnitsB ||
                params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS
              }
              onClick={() => handleClickExante(params.row.tradeOrderRequestId)}
            >
              {t('Exante')}
            </Button>
          ) : (
            <></>
          )}
        </Box>
      ),
    },
    {
      field: 'tradeAmount',
      headerClassName: 'trade-order-header',
      headerName: t('tradeOrders.TradeAmount'),
      flex: 0.3,
      display: 'flex',
      renderHeader: () => (
        <Box>
          <Typography className="small-dg-header">
            {t('tradeOrders.TradeAmount')}
          </Typography>
          <Typography className="small-dg-header italic">
            {t('tradeOrders.Fee')}
          </Typography>
        </Box>
      ),
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          {params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
          params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS ? (
            <CurrencyField
              value={params.row.amount}
              suffix={'andelar'}
              className="small-dg-text2"
              decimals={6}
              fixedDecimals={false}
            ></CurrencyField>
          ) : (
            <CurrencyField
              value={params.row.tradeAmount}
              suffix={params.row.currencyCode}
              className="small-dg-text2"
            ></CurrencyField>
          )}
          {!unlisted &&
          (params.row.tradeOrderType == TradeOrderOperationEnum.BuyUnitsB ||
            params.row.tradeOrderType == TradeOrderOperationEnum.SellUnitsS) ? (
            <StringField
              value={'TBC'}
              className="small-dg-text2 italic"
            ></StringField>
          ) : (
            <CurrencyField
              value={params.row.transactionFee}
              className="small-dg-text2 italic"
            ></CurrencyField>
          )}
          <SecurityDetails
            isinCode={params.value}
            opportunityId={params.row.opportunityId}
            api={api}
            size={SecurityInfoSize.Small}
            unlisted={unlisted}
          />
        </Box>
      ),
    },
  ];

  const useStyles = (padding: number): SxProps =>
    useMemo(
      () => ({
        width: '100%',
        minWidth: '280px',
        border: 0,
        '& .MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '& .MuiDataGrid-columnHeader': {
          paddingLeft: padding,
          paddingRight: padding,
        },
        '& .MuiDataGrid-cell': {
          paddingLeft: padding,
          paddingRight: padding,
        },
      }),
      [padding]
    );

  function Order(props: any) {
    const styles = useStyles(props.Padding);

    return (
      <>
        <div
          style={{
            width: '100%',
            maxHeight: '300px',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            maxWidth: '1472px',
            minHeight: '200px',
          }}
        >
          {props.Orders.length > 0 ? (
            <DataGrid
              key={props.Orders.length} // Rerender when Orders length changes
              rows={props.Orders as ITradeOrderDto[]}
              columns={props.Columns}
              pageSizeOptions={[]}
              hideFooterRowCount
              disableColumnMenu
              disableRowSelectionOnClick
              getRowId={(row) => row.tradeOrderRequestId}
              rowHeight={props.RowHeight}
              columnVisibilityModel={{
                tradeOrderRequestId: !unlisted,
                amount: unlisted,
              }}
              sx={styles}
            />
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }

  useEffect(() => {
    let activeEntityId = getActiveUser(users)?.id;

    if (unlisted) {
      api
        .get<IMultipleTradeOrdersDto[]>(
          `api/unlistedtradeorder/${activeEntityId}`
        )
        .then(
          (res) => {
            setIsFetched(false);
            setOpportunityTradeorders(res.data);
            setIsFetched(true);
          },
          (err) => {
            console.error(err);
          }
        );
    } else {
      api
        .get<IMultipleTradeOrdersDto[]>(`api/tradeorder/${activeEntityId}`)
        .then(
          (res) => {
            setIsFetched(false);
            setOpportunityTradeorders(res.data);
            setIsFetched(true);
          },
          (err) => {
            console.error(err);
          }
        );
    }
  }, []);

  return (
    <React.Fragment>
      {isSigning || isSigned ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="10vh"
          my={6}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {isQrCodeFetched ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="10vh"
            >
              <Box>
                <img
                  style={{ maxWidth: '300px' }}
                  alt="Embedded QR Code"
                  src={
                    'data:image/jpeg;base64,' + initSignResponse?.qrCodeAsBase64
                  }
                />
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      window.location.href = `bankid:///?autostarttoken=${initSignResponse?.autoStartToken}&redirect=null`;
                      setIsQrCodeFetched(false);
                      setIsFetched(false);
                      setIsSigning(true);
                    }}
                  >
                    {t('signing.ConfirmSameDevice')}
                  </Button>
                </div>
              </Box>
            </Box>
          ) : (
            <>
              <Box display={'flex'} height="100%" sx={{ overflowX: 'hidden' }}>
                <Box
                  flex={1}
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'none',
                      md: 'none',
                      lg: 'block',
                    },
                  }}
                >
                  <Grid container spacing={1} minWidth="1070px" margin={0}>
                    <Grid size={{ xs: 12 }}>
                      {!isFetched && (
                        <Box display="flex" justifyContent="center" my={6}>
                          <CircularProgress />
                        </Box>
                      )}
                      <Box marginLeft="16px" marginBottom={1}>
                        {isFetched &&
                          opportunityTradeorders &&
                          opportunityTradeorders.map((c) => {
                            return (
                              <Grid container key={c.opportunityId} mb={4}>
                                <Grid size={{ xs: 12 }}>
                                  <Typography>{c.name}</Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <Typography>
                                    {t('ResponsibleCoeli')}: largest
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    fontWeight={'bold'}
                                  >
                                    {c.ownerName}
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <Order
                                    Orders={c.orders}
                                    Columns={columnsDeskstop}
                                    RowHeight={52}
                                    Padding={'8px'}
                                  />
                                </Grid>
                                <Grid size={{ xs: 11 }}>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Motivation"
                                    InputLabelProps={{
                                      shrink: c.motivation?.length > 0,
                                    }}
                                    fullWidth
                                    multiline
                                    disabled
                                    rows={4}
                                    defaultValue={c.motivation ?? ''}
                                  />
                                </Grid>
                                <Grid size={{ xs: 12 }} pt={2}>
                                  {activeUserCanSign ? (
                                    <>
                                      {unlisted ? (
                                        <>
                                          <Button
                                            size="small"
                                            sx={{ ml: 2 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                              handleClickSign(c.opportunityId)
                                            }
                                            startIcon={
                                              <CheckCircleOutlineIcon />
                                            }
                                          >
                                            {t('Confirm')}
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            size="small"
                                            sx={{ ml: 2 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                              handleClickAccept(
                                                c.opportunityId,
                                                true
                                              )
                                            }
                                            startIcon={
                                              <CheckCircleOutlineIcon />
                                            }
                                          >
                                            {t('Accept')}
                                          </Button>
                                        </>
                                      )}
                                      <Button
                                        size="small"
                                        sx={{ ml: 2 }}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleClickAccept(
                                            c.opportunityId,
                                            false
                                          )
                                        }
                                        startIcon={<HighlightOffIcon />}
                                      >
                                        {t('Reject')}
                                      </Button>
                                    </>
                                  ) : null}
                                  {unlisted != true ? (
                                    <Button
                                      size="small"
                                      sx={{ ml: 2 }}
                                      variant="outlined"
                                      color="primary"
                                      onClick={() =>
                                        handleClickGetAllExante(c.orders)
                                      }
                                      startIcon={<InsertDriveFileIcon />}
                                    >
                                      {t('AllExante')}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  flex={1}
                  sx={{
                    display: {
                      xs: 'none',
                      sm: 'none',
                      md: 'block',
                      lg: 'none',
                    },
                  }}
                >
                  <Grid container spacing={1} minWidth="800px" margin={0}>
                    <Grid size={{ xs: 12 }}>
                      {!isFetched && (
                        <Box display="flex" justifyContent="center" my={6}>
                          <CircularProgress />
                        </Box>
                      )}
                      <Box marginLeft="16px" marginRight="16px">
                        {isFetched &&
                          opportunityTradeorders &&
                          opportunityTradeorders.map((c) => {
                            return (
                              <Grid container key={c.opportunityId} mb={4}>
                                <Grid size={{ xs: 12 }}>
                                  <Typography>{c.name}</Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <Typography>
                                    {t('ResponsibleCoeli')}: medium
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    fontWeight={'bold'}
                                  >
                                    {c.ownerName}
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <Order
                                    Orders={c.orders}
                                    Columns={columnsDeskstopMedium}
                                    RowHeight={52}
                                    Padding={'8px'}
                                  />
                                </Grid>
                                <Grid size={{ xs: 11 }}>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Motivation"
                                    fullWidth
                                    multiline
                                    disabled
                                    InputLabelProps={{
                                      shrink: c.motivation?.length > 0,
                                    }}
                                    rows={4}
                                    defaultValue={c.motivation ?? ''}
                                  />
                                </Grid>
                                <Grid size={{ xs: 12 }} pt={2}>
                                  {activeUserCanSign ? (
                                    <>
                                      {unlisted ? (
                                        <>
                                          <Button
                                            size="small"
                                            sx={{ ml: 2 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                              handleClickSign(c.opportunityId)
                                            }
                                            startIcon={
                                              <CheckCircleOutlineIcon />
                                            }
                                          >
                                            {t('Confirm')}
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            size="small"
                                            sx={{ ml: 2 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                              handleClickAccept(
                                                c.opportunityId,
                                                true
                                              )
                                            }
                                            startIcon={
                                              <CheckCircleOutlineIcon />
                                            }
                                          >
                                            {t('Accept')}
                                          </Button>
                                        </>
                                      )}
                                      <Button
                                        size="small"
                                        sx={{ ml: 2 }}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleClickAccept(
                                            c.opportunityId,
                                            false
                                          )
                                        }
                                        startIcon={<HighlightOffIcon />}
                                      >
                                        {t('Reject')}
                                      </Button>
                                    </>
                                  ) : null}
                                  {unlisted != true ? (
                                    <Button
                                      size="small"
                                      sx={{ ml: 2 }}
                                      variant="outlined"
                                      color="primary"
                                      onClick={() =>
                                        handleClickGetAllExante(c.orders)
                                      }
                                      startIcon={<InsertDriveFileIcon />}
                                    >
                                      {t('AllExante')}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  flex={1}
                  sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}
                >
                  <Grid container spacing={1} minWidth="550px" margin={0}>
                    <Grid size={{ xs: 12 }}>
                      {!isFetched && (
                        <Box display="flex" justifyContent="center" my={6}>
                          <CircularProgress />
                        </Box>
                      )}
                      <Box marginLeft="8px" marginRight="8px">
                        {isFetched &&
                          opportunityTradeorders &&
                          opportunityTradeorders.map((c) => {
                            return (
                              <Grid container key={c.opportunityId} mb={4}>
                                <Grid size={{ xs: 12 }}>
                                  <Typography>{c.name}</Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <Typography>
                                    {t('ResponsibleCoeli')}:
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    fontWeight={'bold'}
                                  >
                                    {c.ownerName}
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <Order
                                    Orders={c.orders}
                                    Columns={columnsDesktopSmall}
                                    RowHeight={64}
                                    Padding={'0px'}
                                  />
                                </Grid>
                                <Grid size={{ xs: 11 }}>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Motivation"
                                    InputLabelProps={{
                                      shrink: c.motivation?.length > 0,
                                    }}
                                    fullWidth
                                    multiline
                                    disabled
                                    rows={4}
                                    defaultValue={c.motivation ?? ''}
                                  />
                                </Grid>
                                <Grid size={{ xs: 12 }} pt={2}>
                                  {activeUserCanSign ? (
                                    <>
                                      {unlisted ? (
                                        <>
                                          <Button
                                            size="small"
                                            sx={{ ml: 2 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                              handleClickSign(c.opportunityId)
                                            }
                                            startIcon={
                                              <CheckCircleOutlineIcon />
                                            }
                                          >
                                            {t('Confirm')}
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            size="small"
                                            sx={{ ml: 2 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                              handleClickAccept(
                                                c.opportunityId,
                                                true
                                              )
                                            }
                                            startIcon={
                                              <CheckCircleOutlineIcon />
                                            }
                                          >
                                            {t('Accept')}
                                          </Button>
                                        </>
                                      )}
                                      <Button
                                        size="small"
                                        sx={{ ml: 2 }}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleClickAccept(
                                            c.opportunityId,
                                            false
                                          )
                                        }
                                        startIcon={<HighlightOffIcon />}
                                      >
                                        {t('Reject')}
                                      </Button>
                                    </>
                                  ) : null}
                                  {unlisted != true ? (
                                    <Button
                                      size="small"
                                      sx={{ ml: 2 }}
                                      variant="outlined"
                                      color="primary"
                                      onClick={() =>
                                        handleClickGetAllExante(c.orders)
                                      }
                                      startIcon={<InsertDriveFileIcon />}
                                    >
                                      {t('AllExante')}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box flex={1} sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <Grid container spacing={1} margin={0}>
                    <Grid size={{ xs: 12 }}>
                      {!isFetched && (
                        <Box display="flex" justifyContent="center" my={6}>
                          <CircularProgress />
                        </Box>
                      )}
                      <Box>
                        {isFetched &&
                          opportunityTradeorders &&
                          opportunityTradeorders.map((c) => {
                            return (
                              <Grid container key={c.opportunityId} mb={4}>
                                <Grid size={{ xs: 12 }}>
                                  <Typography>{c.name}</Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <Typography>
                                    {t('ResponsibleCoeli')}: smallest
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    fontWeight={'bold'}
                                  >
                                    {c.ownerName}
                                  </Typography>
                                </Grid>
                                <Grid size={{ xs: 12 }}>
                                  <Order
                                    Orders={c.orders}
                                    Columns={columnsMobile}
                                    RowHeight={64}
                                    Padding={'0px'}
                                  />
                                </Grid>
                                <Grid size={{ xs: 11 }}>
                                  <TextField
                                    id="outlined-multiline-static"
                                    label="Motivation"
                                    fullWidth
                                    multiline
                                    disabled
                                    InputLabelProps={{
                                      shrink: c.motivation?.length > 0,
                                    }}
                                    rows={4}
                                    defaultValue={c.motivation ?? ''}
                                  />
                                </Grid>
                                <Grid size={{ xs: 12 }} pt={2}>
                                  {activeUserCanSign ? (
                                    <>
                                      {unlisted ? (
                                        <>
                                          <Button
                                            size="small"
                                            sx={{ ml: 2 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                              handleClickSign(c.opportunityId)
                                            }
                                            startIcon={
                                              <CheckCircleOutlineIcon />
                                            }
                                          >
                                            {t('Confirm')}
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            size="small"
                                            sx={{ ml: 2 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() =>
                                              handleClickAccept(
                                                c.opportunityId,
                                                true
                                              )
                                            }
                                            startIcon={
                                              <CheckCircleOutlineIcon />
                                            }
                                          >
                                            {t('Accept')}
                                          </Button>
                                        </>
                                      )}
                                      <Button
                                        size="small"
                                        sx={{ ml: 2 }}
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleClickAccept(
                                            c.opportunityId,
                                            false
                                          )
                                        }
                                        startIcon={<HighlightOffIcon />}
                                      >
                                        {t('Reject')}
                                      </Button>
                                    </>
                                  ) : null}
                                  {unlisted != true ? (
                                    <Button
                                      size="small"
                                      sx={{ ml: 2, mt: 2 }}
                                      variant="outlined"
                                      color="primary"
                                      onClick={() =>
                                        handleClickGetAllExante(c.orders)
                                      }
                                      startIcon={<InsertDriveFileIcon />}
                                    >
                                      {t('AllExante')}
                                    </Button>
                                  ) : (
                                    <></>
                                  )}
                                </Grid>
                              </Grid>
                            );
                          })}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
}

TradeOrder.whyDidYouRender = true;
export default TradeOrder;
