import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useRef, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { useApiClient } from '../../hooks/apiClient';
import { useAppSelector } from '../../redux/hooks';
import { getMainUser } from '../../redux/stateFuncs';
import { FaMarketValueDto } from '../../models/dto/faMarketValueDto';
import { useTranslation } from 'react-i18next';
import { format, add, startOfYear, startOfDay } from 'date-fns';
import InfoTableWidget from './InfoTableWidget';
import { InfoTableWidgetType } from '../../Enums';
import { Button } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { UTCDate } from '@date-fns/utc';

export interface ProgressGraphHCPRops {
  totalView: boolean;
  navBar: boolean;
}

interface PeriodButtonProps {
  title: string;
  rangeFunc: () => void;
  active: boolean;
}

const PeriodButton = (props: PeriodButtonProps) => {
  var css: string = 'btn-small';
  if (props.active) css += ' btn-small-selected';

  return (
    <>
      <Grid ml={1}>
        <Button className={css} onClick={props.rangeFunc}>
          {props.title}
        </Button>
      </Grid>
    </>
  );
};

const ProgressGraphHC = (props: ProgressGraphHCPRops) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const [activePeriod, setActivePeriod] = useState<number>(0);

  const ytdPeriod = 5;

  const [dateRangeVisible, setDateRangeVisible] = useState<boolean>(false);

  const [startDate, setStartDate] = useState<UTCDate | null>(null);
  const [endDate, setEndDate] = useState<UTCDate | null>(null);

  const [portfoliosMarketValues, setPortfoliosMarketValues] =
    useState<FaMarketValueDto[]>();
  const [graphPortfoliosMarketValues, setGraphPortfoliosMarketValues] =
    useState<FaMarketValueDto[]>();

  const portfolios = useAppSelector((state) => state.portfolios);
  const users = useAppSelector((state) => state.users);
  const mainUser = getMainUser(users);

  const [options, setOptions] = useState<Highcharts.Options | undefined>(
    undefined
  );

  const api = useApiClient();
  const { t } = useTranslation();

  var now = format(new Date(), 'yyyy-MM-dd');

  useEffect(() => {
    setIsLoading(true);
    loadData(props.totalView);
    setOptions((prev) => {
      return {
        ...prev,
        accessibility: {
          enabled: false,
        },
        chart: {
          height: 480,
          style: {
            fontFamily: 'Gotham',
          },
        },
        navigator: {
          enabled: false,
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          plotLines: [
            {
              color: '#00000060',
              width: 2,
              value: 0,
              zIndex: 10,
            },
          ],
        },
        time: {
          useUTC: false,
        },
        rangeSelector: {
          buttons: [
            {
              type: 'month',
              count: 3,
              text: '3m',
            },
            {
              type: 'month',
              count: 6,
              text: '6m',
            },
            {
              type: 'ytd',
              text: 'YTD',
            },
            {
              type: 'year',
              count: 1,
              text: '1y',
            },
            {
              type: 'all',
              text: 'All',
              dataGrouping: {
                forced: true,
                units: [['day', [1]]],
              },
            },
          ],
          inputEnabled: false, // Range selector off
        },
        series: [
          {
            name: t('Progress'),
            tooltip: {
              pointFormat: '{series.name}: <b>{point.y}</b>',
              valueSuffix: ' %',
              valueDecimals: 2,
              dateTimeLabelFormats: {
                hour: '%e. %b %Y',
                day: '%e. %b %Y',
                week: '%e. %b %Y',
                month: '%e. %b %Y',
                year: '%e. %b %Y',
              },
            },
            //data: data,
            type: 'line',
            color: '#C26E3D',
            threshold: null,
            navigatorOptions: {},
          },
        ],
      } as Highcharts.Options;
    });
  }, [portfolios]);

  useEffect(() => {
    const data = graphPortfoliosMarketValues?.map((p) => [
      Date.parse(p.date),
      p.relativeValue,
    ]);

    if (data !== undefined && data.length > 0) {
      var min = data[0][0];
      var max = data[data.length - 1][0];

      chartComponentRef.current?.chart.series[0].setData(data);
      chartComponentRef.current?.chart.xAxis[0].setExtremes(min, max);
    }
  }, [graphPortfoliosMarketValues]);

  useEffect(() => {
    if (portfoliosMarketValues !== undefined) {
      setRange(
        startOfYear(startOfDay(new Date()).getTime()).getTime(),
        startOfDay(new Date()).getTime(),
        ytdPeriod
      );
    }
  }, [portfoliosMarketValues]);

  useEffect(() => {
    if (dateRangeVisible && startDate !== null && endDate !== null) {
      setRange(startDate.getTime(), endDate.getTime(), -1);
    }
  }, [dateRangeVisible, startDate, endDate]);

  // Is over min, in between or lower & equal to max
  const isBetween = (date: Date, min: Date, max: Date) =>
    date.getTime() > min.getTime() && date.getTime() <= max.getTime();

  function setRange(min: number, max: number, period: number) {
    var startDate = startOfDay(new Date(min));
    var endDate = startOfDay(new Date(max));
    if (portfoliosMarketValues !== undefined) {
      var pmv: FaMarketValueDto[] = [];
      var twr: number = 1.0;
      pmv.push({
        date: format(startDate, 'yyyy-MM-dd'),
        relativeValue: 0,
      } as FaMarketValueDto);

      portfoliosMarketValues.forEach((i) => {
        if (isBetween(new Date(Date.parse(i.date)), startDate, endDate)) {
          twr *= 1.0 + i.relativeValue;
          var twrRelativeValue = (twr - 1.0) * 100;
          pmv.push({
            date: i.date,
            relativeValue: twrRelativeValue,
          } as FaMarketValueDto);
        }
      });
      setGraphPortfoliosMarketValues(pmv);
      setActivePeriod(period);
    }
  }

  function toogleDateRange() {
    setDateRangeVisible(!dateRangeVisible);
  }

  function loadData(totalView: boolean) {
    if (totalView) {
      // All for customer
      const postData = {
        socialSecurityNumber: mainUser?.socialSecurityNumber,
        fromDate: '1990-01-01',
        toDate: now,
      };
      api
        .post<FaMarketValueDto[]>(
          'api/FaSecurity/GetConsolidatedDailyCustomerValues',
          postData
        )
        .then((res) => {
          setPortfoliosMarketValues(res.data);
          setIsLoading(false);
          setError(null);
        })
        .catch((err) => {
          setError('Error loading portfolio market values');
        });
    } else {
      // Selected portfolios
      const postData = {
        portfolioIds: portfolios
          .filter((p) => p.show === true)
          .map((p) => p.id),
        fromDate: '1990-01-01',
        toDate: now,
      };
      api
        .post<FaMarketValueDto[]>(
          'api/FaSecurity/GetConsolidatedDailyPortfoliosValues',
          postData
        )
        .then((res) => {
          setPortfoliosMarketValues(res.data);
          setIsLoading(false);
          setError(null);
        })
        .catch((err) => {
          setError('Error loading portfolio market values');
        });
    }
  }

  return (
    <>
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid container>
                <Grid width={'100%'}>
                  <InfoTableWidget
                    title={t('Progress') + ' (%)'}
                    showPieChart={false}
                    showValues={false}
                    type={InfoTableWidgetType.Text}
                  >
                    {options ? (
                      <>
                        {portfoliosMarketValues !== undefined ? (
                          <Grid container pl={1} pt={1}>
                            <Grid>
                              <Typography variant="body2">Period</Typography>
                            </Grid>
                            {dateRangeVisible ? (
                              <Box ml={1}>
                                <DatePicker
                                  disableFuture={true}
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      paddingTop: '3px',
                                      paddingLeft: '4px',
                                      paddingBottom: '1px',
                                      paddingRight: '2px',
                                      width: '84px',
                                      fontSize: '0.825em',
                                    },
                                  }}
                                  slotProps={{
                                    openPickerButton: {
                                      sx: {
                                        '& .MuiSvgIcon-root': {
                                          fontSize: '1rem',
                                        },
                                      },
                                    },
                                  }}
                                  format="yyyy-MM-dd"
                                  value={startDate}
                                  onChange={(newValue) => {
                                    setStartDate(
                                      new UTCDate(
                                        newValue!.getFullYear(),
                                        newValue!.getMonth(),
                                        newValue!.getDate()
                                      )
                                    );
                                  }}
                                />
                                <Typography noWrap display="inline">
                                  {' '}
                                  -{' '}
                                </Typography>
                                <DatePicker
                                  disableFuture={true}
                                  sx={{
                                    '& .MuiInputBase-input': {
                                      paddingTop: '3px',
                                      paddingLeft: '4px',
                                      paddingBottom: '1px',
                                      paddingRight: '2px',
                                      width: '84px',
                                      fontSize: '0.825em',
                                    },
                                  }}
                                  slotProps={{
                                    openPickerButton: {
                                      sx: {
                                        '& .MuiSvgIcon-root': {
                                          fontSize: '1rem',
                                        },
                                      },
                                    },
                                  }}
                                  format="yyyy-MM-dd"
                                  value={endDate}
                                  onChange={(newValue) => {
                                    setEndDate(
                                      new UTCDate(
                                        newValue!.getFullYear(),
                                        newValue!.getMonth(),
                                        newValue!.getDate()
                                      )
                                    );
                                  }}
                                />
                              </Box>
                            ) : (
                              <>
                                <PeriodButton
                                  title="1v"
                                  rangeFunc={() => {
                                    setRange(
                                      add(startOfDay(new Date()).getTime(), {
                                        weeks: -1,
                                      }).getTime(),
                                      startOfDay(new Date()).getTime(),
                                      0
                                    );
                                  }}
                                  active={activePeriod === 0}
                                />
                                <PeriodButton
                                  title="1m"
                                  rangeFunc={() => {
                                    setRange(
                                      add(startOfDay(new Date()).getTime(), {
                                        months: -1,
                                      }).getTime(),
                                      startOfDay(new Date()).getTime(),
                                      1
                                    );
                                  }}
                                  active={activePeriod === 1}
                                />
                                <PeriodButton
                                  title="3m"
                                  rangeFunc={() => {
                                    setRange(
                                      add(startOfDay(new Date()).getTime(), {
                                        months: -3,
                                      }).getTime(),
                                      startOfDay(new Date()).getTime(),
                                      2
                                    );
                                  }}
                                  active={activePeriod === 2}
                                />
                                <PeriodButton
                                  title="6m"
                                  rangeFunc={() => {
                                    setRange(
                                      add(startOfDay(new Date()).getTime(), {
                                        months: -6,
                                      }).getTime(),
                                      startOfDay(new Date()).getTime(),
                                      3
                                    );
                                  }}
                                  active={activePeriod === 3}
                                />
                                <PeriodButton
                                  title="12m"
                                  rangeFunc={() => {
                                    setRange(
                                      add(startOfDay(new Date()).getTime(), {
                                        months: -12,
                                      }).getTime(),
                                      startOfDay(new Date()).getTime(),
                                      4
                                    );
                                  }}
                                  active={activePeriod === 4}
                                />
                                <PeriodButton
                                  title="YTD"
                                  rangeFunc={() => {
                                    setRange(
                                      startOfYear(
                                        startOfDay(new Date()).getTime()
                                      ).getTime(),
                                      startOfDay(new Date()).getTime(),
                                      5
                                    );
                                  }}
                                  active={activePeriod === 5}
                                />
                                <PeriodButton
                                  title="3y"
                                  rangeFunc={() => {
                                    setRange(
                                      add(startOfDay(new Date()).getTime(), {
                                        years: -3,
                                      }).getTime(),
                                      startOfDay(new Date()).getTime(),
                                      6
                                    );
                                  }}
                                  active={activePeriod === 6}
                                />
                                <PeriodButton
                                  title="5y"
                                  rangeFunc={() => {
                                    setRange(
                                      add(startOfDay(new Date()).getTime(), {
                                        years: -5,
                                      }).getTime(),
                                      startOfDay(new Date()).getTime(),
                                      7
                                    );
                                  }}
                                  active={activePeriod === 7}
                                />
                                <PeriodButton
                                  title="All"
                                  rangeFunc={() => {
                                    setRange(
                                      Date.parse(
                                        portfoliosMarketValues[0].date
                                      ),
                                      startOfDay(new Date()).getTime(),
                                      8
                                    );
                                  }}
                                  active={activePeriod === 8}
                                />
                              </>
                            )}
                            <Grid ml={1}>
                              <Button
                                className="btn-small"
                                onClick={() => toogleDateRange()}
                              >
                                {' '}
                                ...{' '}
                              </Button>
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={options}
                          constructorType={'stockChart'}
                          ref={chartComponentRef}
                        />
                      </>
                    ) : (
                      <>
                        <Box display="flex" justifyContent="center" my={6}>
                          <Typography>{t('MarketDataMissing')}</Typography>
                        </Box>
                      </>
                    )}
                  </InfoTableWidget>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
};

export default ProgressGraphHC;
