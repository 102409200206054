import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { format, isValid, parseISO } from 'date-fns';
import { ReactNode, useState } from 'react';
import { useAppSelector } from '../../redux/hooks';
import { getActiveUser } from '../../redux/stateFuncs';
import { useApiClient } from '../../hooks/apiClient';
import React from 'react';
import { useTranslation } from 'react-i18next';
import DownloadIcon from '@mui/icons-material/FileDownload';
import { DatePicker } from '@mui/x-date-pickers';
import { UTCDate } from '@date-fns/utc';

enum ReportTypeEnum {
  Positions = 1,
  Transactions = 2,
  RealizedProfit = 3,
  BankStatements = 4,
}

export default function PortfolioReports() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);

  const [dateRange, setDateRange] = React.useState('YTD');

  const [startDate, setStartDate] = React.useState<UTCDate | null>(null);
  const [endDate, setEndDate] = React.useState<UTCDate | null>(null);

  const api = useApiClient();
  const portfolios = useAppSelector((state) => state.portfolios);
  const customers = useAppSelector((state) => state.customers);

  const [selectedPortfolioId, setSelectedPortfolioId] = React.useState(
    portfolios.length > 0 ? portfolios[0].id : ''
  );

  var now = format(new Date(), 'yyyy-MM-dd');

  function handleChangeDate(
    event: SelectChangeEvent<any>,
    child: ReactNode
  ): void {
    setDateRange(event.target.value);
  }

  function handleDownloadReport(reportType: ReportTypeEnum): void {
    const postData = {
      portfolioId: [selectedPortfolioId],
    };

    if (dateRange == 'C') {
      if (startDate == null || endDate == null) {
        alert('Missing start/end dates');
        return;
      }
      if (!isValid(startDate) || !isValid(startDate)) {
        alert('Invalid start/end dates');
        return;
      }
    }

    var startDateRange: UTCDate = new UTCDate();
    var endDateRange: UTCDate = new UTCDate();

    switch (dateRange) {
      case 'YTD':
        startDateRange.setFullYear(new UTCDate().getFullYear(), 0, 1);
        break;
      case 'M12':
        startDateRange.setFullYear(new UTCDate().getFullYear() - 1);
        break;
      case 'M24':
        startDateRange.setFullYear(new UTCDate().getFullYear() - 2);
        break;
      case 'Y5':
        startDateRange.setFullYear(new UTCDate().getFullYear() - 5);
        break;
      case 'PY':
        startDateRange.setFullYear(new UTCDate().getFullYear() - 1, 0, 1);
        endDateRange.setFullYear(new UTCDate().getFullYear() - 1, 11, 31);
        break;
      case 'C':
        startDateRange = new UTCDate(startDate!);
        endDateRange = new UTCDate(endDate!);
        break;
    }

    var reportUrl = '';
    var reportName = '';

    switch (reportType) {
      case ReportTypeEnum.Positions:
        (postData as any).reportDate = endDateRange;
        reportUrl = 'GetPositionsReport';
        reportName = 'Positions';
        break;
      case ReportTypeEnum.Transactions:
        (postData as any).startDate = startDateRange;
        (postData as any).endDate = endDateRange;
        reportUrl = 'GetTransactionsReport';
        reportName = 'Transactions';
        break;
      case ReportTypeEnum.BankStatements:
        (postData as any).startDate = startDateRange;
        (postData as any).endDate = endDateRange;
        reportUrl = 'GetBankStatementReport';
        reportName = 'BankStatements';
        break;
      case ReportTypeEnum.RealizedProfit:
        (postData as any).startDate = startDateRange;
        (postData as any).endDate = endDateRange;
        reportUrl = 'GetRealizedProfitReport';
        reportName = 'P_L_RealizedProfits';

        break;
    }

    // Create filename
    const portfolioShortName = portfolios.find(
      (f) => f.id == selectedPortfolioId
    )?.shortName;

    var filename = reportName + '_' + portfolioShortName + '_';
    if (reportType != ReportTypeEnum.Positions)
      filename += format(startDateRange, 'yyyy.MM.dd') + '_';

    filename += format(endDateRange, 'yyyy.MM.dd') + '_';
    filename += '.pdf';

    setIsLoading(true);
    api
      .post(`api/report/` + reportUrl, postData, {
        responseType: 'arraybuffer',
      })
      .then(
        (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          var a = document.createElement('a');
          a.href = url;
          a.download = filename;
          a.click();
          a.remove();
          setTimeout(() => window.URL.revokeObjectURL(url), 100);
          setIsLoading(false);
        },
        (err) => {
          alert('Error');
          setIsLoading(false);
        }
      );
  }

  function handlePortfolioChange(
    event: SelectChangeEvent<string>,
    child: ReactNode
  ): void {
    setSelectedPortfolioId(event.target.value);
  }

  return (
    <Box className="portfolio-overview">
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          <Grid container>
            <Grid>
              <Box className="info-table-widet" mb={2}>
                <Grid container>
                  <Grid
                    size={{ xs: 12 }}
                    paddingTop={1}
                    borderBottom={'1px solid'}
                  >
                    <Typography variant="h5">
                      {t('investments.Reports')}
                    </Typography>
                  </Grid>
                  <Grid
                    size={{ xs: 12 }}
                    container
                    paddingTop={1}
                    minWidth={320}
                    justifyItems="center"
                  >
                    <Grid size={{ xs: 2, md: 6 }} minWidth={320}>
                      <Box pl={1} pt={{ xs: 1, sm: 2 }} pb={{ xs: 0, sm: 2 }}>
                        <InputLabel id="portfolios-label">
                          {t('Portfolio')}
                        </InputLabel>
                        <Select
                          labelId="portfolios-label"
                          id="portfolios"
                          displayEmpty
                          value={selectedPortfolioId}
                          label={t('Portfolio')}
                          onChange={handlePortfolioChange}
                          sx={{
                            minWidth: '310px',
                            maxWidth: '310px',
                            height: '32px',
                            '& .MuiOutlinedInput-notchedOutline legend': {
                              width: 0,
                            },
                          }}
                        >
                          {portfolios
                            .filter((p) =>
                              customers
                                .map((c) => c.contactId)
                                .includes(p.ownerId)
                            )
                            .map((p) => {
                              return (
                                <MenuItem value={p.id} key={p.id}>
                                  {customers.find(
                                    (c) => c.contactId == p.ownerId
                                  )?.name +
                                    ' - ' +
                                    p.shortName}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </Box>
                    </Grid>
                    <Grid size={{ xs: 2, md: 6 }} minWidth={320}>
                      <Box pl={1} pt={2} pb={2}>
                        <InputLabel id="date-label">{t('Date')}</InputLabel>
                        <Select
                          labelId="date-label"
                          id="select-date"
                          value={dateRange}
                          label="Date"
                          displayEmpty
                          onChange={handleChangeDate}
                          sx={{
                            minWidth: '310px',
                            maxWidth: '310px',
                            height: '32px',
                            '& .MuiOutlinedInput-notchedOutline legend': {
                              width: 0,
                            },
                          }}
                        >
                          <MenuItem value={'YTD'}>YTD</MenuItem>
                          <MenuItem value={'M12'}>
                            12 {t('Months').toLowerCase()}
                          </MenuItem>
                          <MenuItem value={'M24'}>
                            24 {t('Months').toLowerCase()}
                          </MenuItem>
                          <MenuItem value={'Y5'}>
                            5 {t('Years').toLowerCase()}
                          </MenuItem>
                          <MenuItem value={'PY'}>{t('PreviousYear')}</MenuItem>
                          <MenuItem value={'C'}>{t('CustomDates')}...</MenuItem>
                        </Select>
                      </Box>
                    </Grid>
                    {dateRange == 'C' ? (
                      <>
                        <Grid size={{ xs: 2, md: 6 }} minWidth={320}>
                          <Box
                            pl={1}
                            pt={{ xs: 1, sm: 2 }}
                            pb={{ xs: 0, sm: 2 }}
                          >
                            <InputLabel>{t('StartDate')}</InputLabel>
                            <DatePicker
                              sx={{
                                '& .MuiInputBase-input': {
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                },
                              }}
                              format="yyyy-MM-dd"
                              value={startDate}
                              onChange={(newValue) => {
                                setStartDate(
                                  new UTCDate(
                                    newValue!.getFullYear(),
                                    newValue!.getMonth(),
                                    newValue!.getDate()
                                  )
                                );
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid size={{ xs: 2, md: 6 }} minWidth={320}>
                          <Box
                            pl={1}
                            pt={{ xs: 1, sm: 2 }}
                            pb={{ xs: 0, sm: 2 }}
                          >
                            <InputLabel>{t('EndDate')}</InputLabel>
                            <DatePicker
                              sx={{
                                '& .MuiInputBase-input': {
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                },
                              }}
                              format="yyyy-MM-dd"
                              value={endDate}
                              onChange={(newValue) => {
                                setEndDate(
                                  new UTCDate(
                                    newValue!.getFullYear(),
                                    newValue!.getMonth(),
                                    newValue!.getDate()
                                  )
                                );
                              }}
                            />
                          </Box>
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}

                    {isLoading ? (
                      <Grid size={{ xs: 12 }} className="rows">
                        <Box display="flex" justifyContent="center" my={6}>
                          <CircularProgress />
                        </Box>
                      </Grid>
                    ) : (
                      <>
                        <Grid size={{ xs: 12 }} className="rows" paddingTop={1}>
                          <Button
                            className="document-name"
                            size="small"
                            sx={{ textTransform: 'none' }}
                            startIcon={
                              <DownloadIcon fontSize="small"></DownloadIcon>
                            }
                            onClick={() =>
                              handleDownloadReport(ReportTypeEnum.Positions)
                            }
                          >
                            {t('investments.Positions')}
                          </Button>
                        </Grid>
                        <Grid size={{ xs: 12 }} className="rows" paddingTop={1}>
                          <Button
                            className="document-name"
                            size="small"
                            sx={{ textTransform: 'none' }}
                            startIcon={
                              <DownloadIcon fontSize="small"></DownloadIcon>
                            }
                            onClick={() =>
                              handleDownloadReport(
                                ReportTypeEnum.RealizedProfit
                              )
                            }
                          >
                            {t('PLRealizedProfit')}
                          </Button>
                        </Grid>
                        <Grid size={{ xs: 12 }} className="rows" paddingTop={1}>
                          <Button
                            className="document-name"
                            size="small"
                            sx={{ textTransform: 'none' }}
                            startIcon={
                              <DownloadIcon fontSize="small"></DownloadIcon>
                            }
                            onClick={() =>
                              handleDownloadReport(ReportTypeEnum.Transactions)
                            }
                          >
                            {t('investments.Transactions')}
                          </Button>
                        </Grid>
                        <Grid size={{ xs: 12 }} className="rows" paddingTop={1}>
                          <Button
                            className="document-name"
                            size="small"
                            sx={{ textTransform: 'none' }}
                            startIcon={
                              <DownloadIcon fontSize="small"></DownloadIcon>
                            }
                            onClick={() =>
                              handleDownloadReport(
                                ReportTypeEnum.BankStatements
                              )
                            }
                          >
                            {t('BankStatements')}
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
