import {
  Box,
  CircularProgress,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InfoTableWidgetType } from '../../Enums';
import { useApiClient } from '../../hooks/apiClient';
import { FaPortfolioRisksDto } from '../../models/dto/faRiskDto';
import { useAppSelector } from '../../redux/hooks';
import { getActiveUser } from '../../redux/stateFuncs';
import InfoTableWidget, { IInfoData } from './InfoTableWidget';
import {
  RiskRecommendationsDto,
  coeli_advisorrecomrisklevelforcustomer,
} from '../../models/dto/riskRecommendationsDto';
import { EnumDictionary } from '../../util/common';

export interface PortfolioIdsRequest {
  portfolioIds: string[];
}

export default function Risk() {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const portfolios = useAppSelector((state) => state.portfolios);
  const [riskData, setRiskData] = useState<FaPortfolioRisksDto>();

  const customers = useAppSelector((state) => state.customers);

  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  var activeUser = getActiveUser(users);
  const { t } = useTranslation();

  const riskLevelOptions: EnumDictionary<
    coeli_advisorrecomrisklevelforcustomer,
    string
  > = {
    [coeli_advisorrecomrisklevelforcustomer.BankAccount]: t('Bankaccount'),
    [coeli_advisorrecomrisklevelforcustomer.Low]: t('Low'),
    [coeli_advisorrecomrisklevelforcustomer.MediumLow]: t('Medium-Low'),
    [coeli_advisorrecomrisklevelforcustomer.Medium]: t('Medium'),
    [coeli_advisorrecomrisklevelforcustomer.MediumHigh]: t('Medium-High'),
    [coeli_advisorrecomrisklevelforcustomer.High]: t('High'),
    [coeli_advisorrecomrisklevelforcustomer.Speculations]: t('Speculation'),
  };

  const [selectedCustomerId, setSelectedCustomerId] = useState(
    activeUser?.socialSecurityNumber
  );

  useEffect(() => {
    setIsLoading(true);

    var user = users.find((c) => c.socialSecurityNumber === selectedCustomerId);

    const postData = {
      portfolioIds: portfolios
        .filter((p) => p.ownerId === selectedCustomerId)
        .map((p) => p.id),
    } as PortfolioIdsRequest;
    api
      .post<FaPortfolioRisksDto>('api/FaSecurity/GetPortfolioRisks', postData)
      .then((res) => {
        var data: FaPortfolioRisksDto = res.data;
        // Sort by share desc
        data.risks = data.risks.sort((d1, d2) => {
          return d1.share > d2.share ? -1 : 1;
        });
        data.tradeFrequencies = data.tradeFrequencies.sort((d1, d2) => {
          return d1.share > d2.share ? -1 : 1;
        });

        var ctrl = 'investorProfile';
        var reqId = user?.socialSecurityNumber;
        if (user?.isCompany) {
          ctrl = 'investorProfileCompany';
          reqId = user.id;
        }

        api
          .get<RiskRecommendationsDto>(
            `api/${ctrl}/GetRiskRecommendations/${reqId}`
          )
          .then((res) => {
            data.recommendations = res.data;
            setRiskData(data);
            setIsLoading(false);
            setError(null);
          })
          .catch((err) => {
            console.log(err);
            setError('Error loading risks recommendations');
          });
      })
      .catch((err) => {
        console.log(err);
        setError('Error loading portfolio risks');
      });
  }, [portfolios, selectedCustomerId]);

  function handleCustomerChange(
    event: SelectChangeEvent<string>,
    child: ReactNode
  ): void {
    setSelectedCustomerId(event.target.value);
  }

  function getAverageRiskLevel(avgLevel: number | undefined): string {
    if (avgLevel === undefined) return t('NotSpecified');
    else {
      if (avgLevel <= 1.0)
        return riskLevelOptions[
          coeli_advisorrecomrisklevelforcustomer.BankAccount
        ];
      else if (avgLevel <= 2.0)
        return riskLevelOptions[coeli_advisorrecomrisklevelforcustomer.Low];
      else if (avgLevel <= 3.0)
        return riskLevelOptions[
          coeli_advisorrecomrisklevelforcustomer.MediumLow
        ];
      else if (avgLevel <= 4.0)
        return riskLevelOptions[coeli_advisorrecomrisklevelforcustomer.Medium];
      else if (avgLevel <= 5.0)
        return riskLevelOptions[
          coeli_advisorrecomrisklevelforcustomer.MediumHigh
        ];
      else if (avgLevel <= 6.0)
        return riskLevelOptions[coeli_advisorrecomrisklevelforcustomer.High];
      else
        return riskLevelOptions[
          coeli_advisorrecomrisklevelforcustomer.Speculations
        ];
    }
  }

  return (
    <>
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid container pt={1} pl={1}>
                <Grid size={{ xs: 12 }}>
                  <Box className="info-table-widet" mb={2}>
                    <Grid
                      container
                      paddingTop={1}
                      minWidth={320}
                      justifyItems="center"
                    >
                      <Grid size={{ xs: 2, md: 12 }} minWidth={320}>
                        <Box pl={1} pt={{ xs: 1, sm: 2 }} pb={{ xs: 0, sm: 2 }}>
                          <InputLabel id="customers-label">
                            {t('PersonCompany')}
                          </InputLabel>
                          <Select
                            labelId="customers-label"
                            id="customers"
                            displayEmpty
                            value={selectedCustomerId}
                            label={t('PersonCompany')}
                            onChange={handleCustomerChange}
                            sx={{
                              minWidth: '310px',
                              maxWidth: '310px',
                              height: '32px',
                              '& .MuiOutlinedInput-notchedOutline legend': {
                                width: 0,
                              },
                            }}
                          >
                            {customers.map((c) => {
                              return (
                                <MenuItem value={c.contactId} key={c.contactId}>
                                  {c.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid paddingTop={1} borderBottom={'1px solid'}>
                      <Typography variant="h5">
                        {t('Recommendations')}
                      </Typography>
                    </Grid>
                    <Grid paddingTop={1}>
                      <Typography variant="body2">
                        {t('RecommendationsRiskLevel')}:
                      </Typography>
                      <Typography>
                        {riskData?.recommendations.recommendedRiskLevel
                          ? riskLevelOptions[
                              riskData?.recommendations.recommendedRiskLevel
                            ]
                          : t('NotSpecified')}
                      </Typography>
                    </Grid>
                    <Grid paddingTop={1}>
                      <Typography variant="body2">
                        {t('CurrentRiskLevel')}:
                      </Typography>
                      <Typography>
                        {getAverageRiskLevel(riskData?.averageRisk)}
                      </Typography>
                    </Grid>
                    <Grid paddingTop={1}>
                      <Typography variant="body2">
                        {t(
                          'investorProfile.AllocationsAssetsHighLiquidityDec'
                        ) +
                          ' (' +
                          t('TradingPattern') +
                          ')'}
                        :
                      </Typography>
                      <Typography>
                        {riskData?.recommendations.recommendedAllocHighLiquidity
                          ? riskData?.recommendations
                              .recommendedAllocHighLiquidity + ' %'
                          : t('NotSpecified')}
                      </Typography>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>

              <Grid container>
                <Grid
                  size={{ lg: 12, desktop1300: 6 }}
                  padding={1}
                  minWidth={265}
                  maxWidth={656}
                >
                  <Box marginBottom={2}>
                    <InfoTableWidget
                      title={t('RiskLevelSecurities')}
                      type={InfoTableWidgetType.Percentage}
                      data={riskData?.risks
                        .filter((f) => f.share > 0)
                        .map(
                          (ad) =>
                            ({
                              key: ad.riskName,
                              value: ad.share * 100,
                            } as IInfoData)
                        )}
                      showPieChart={true}
                      showValues={true}
                    ></InfoTableWidget>
                  </Box>
                </Grid>
                <Grid
                  size={{ lg: 12, desktop1300: 6 }}
                  padding={1}
                  minWidth={265}
                  maxWidth={656}
                >
                  <Box marginBottom={2}>
                    <InfoTableWidget
                      title={t('TradingPattern')}
                      type={InfoTableWidgetType.Percentage}
                      data={riskData?.tradeFrequencies
                        .filter((f) => f.share > 0)
                        .map(
                          (ad) =>
                            ({
                              key: ad.riskName,
                              value: ad.share * 100,
                            } as IInfoData)
                        )}
                      showPieChart={true}
                      showValues={true}
                    ></InfoTableWidget>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
