import React, { useEffect, useState } from 'react';
import { Field, Formik, useFormikContext } from 'formik';
import { getActiveUser, getMainUser } from '../../redux/stateFuncs';

import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormGroup,
  Checkbox,
  Link,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import {
  IInvestorProfile,
  InvestorCategory,
} from '../../models/investmentProfileStructure';

import { RadioGroupFormField } from '../formfields/RadioGroupFormField';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { useApiClient } from '../../hooks/apiClient';
import {
  ToDoEnum,
  understandingCoeliPrivateEquityAdvisorHasTrainedMeTo,
  understandingCoeliRealEstateAdvisorHasTrainedMeTo,
  UnlistedEquitiesTradedPreviouslyOptions,
  witchdoesntwant,
} from '../../Enums';
import { useTranslation } from 'react-i18next';
import { setToDoComplete } from '../../redux/slices/todo';

let initialValues: IInvestorProfile = {
  id: '',
  approverId: '',
  ownerId: '',
  statuscode: '',
  rejectComment: '',
  ownerName: '',

  //Basic Info
  investorCategory: 0,
  professionalInvestor: '',
  professionalInvestorAssetUnderManagement: '',

  managementDecisionStructureOwnDecisions: false,
  managementDecisionStructureDiscretionary: false,
  managementDecisionStructurePresentedInvestmentCase: false,

  shareEquity: '',
  shareEquityFunds: '',
  shareInterestFunds: '',
  shareInterestInstruments: '',
  shareHedgeFunds: '',
  sharePrivateEquityFunds: '',
  shareRealEstateFunds: '',
  shareUnlistedEquity: '',
  summarySituationProfessionalInvestors: '',

  //Family Situation and Employment
  employmentEmployed: false,
  employmentEntrepreneur: false,
  employmentRetired: false,
  employmentLiveOfWealth: false,
  employmentStudent: false,
  employmentUnemployed: false,

  familySituation: 0,
  children: 0,
  noOfMinorsChildrenFinancialResponsibilities: 0,
  summaryFamilySituationEmployment: '',
  incomeEmploymentAndPensionMonthAfterTax: null,
  annualIncomeFromCapital: null,
  monthlyCost: null,
  plannedRetirement: 0,
  externalAssetsCash: null,
  externalAssetsEquity: null,
  externalAssetsInterestFunds: null,
  externalAssetsBonds: null,
  externalAssetsEquityFunds: null,
  externalAssetsAlternativInvestments: null,
  externalAssetsMixtFundsOrDisk: null,
  externalAssetsPrivateEquity: null,
  externalAssetsRealEstate: null,
  externalAssetsUnlistedEquity: null,
  externalAssetsOwnedCompanies: null,
  pensionAssets: null,
  realEstateValue: null,
  realEstateLoans: null,
  otherLoans: null,
  assessedRiskDiscrepancyClientAdviser: '',
  riskAssessmentDiscrepancyDescription: '',
  expectedChangeAssetsAndLiabilities: '',
  whatAreExpectedChangeInAssetsAndLiabilities: '',
  expectedChangesRevenueAndExpenses: '',
  whatAreExpectedChangesRevenueAndExpenses: '',
  summaryOfIncomeExpensesAndAssets: '',

  investmentObjectives: 0,
  investmentGoalsDescribe: '',
  investmentHorizon: 0,
  investmentHorizonDescribe: '',
  dependentOnCapitalShortTerm: '',
  annualCapitalDependency1to3y: '',
  annualCapitalDependency3to5y: '',
  dependentOnCapitalLongTerm: '',
  annualcapitaldependence5to10y: '',
  annualcapitaldependencymore10y: '',
  investmentSummaryObjectivesHorizon: '',

  experienceProfession: '',
  professionWhich: '',
  experienceEducation: '',
  educationWhich: '',
  listedEquityTradedPreviously: '',
  listedEquityWhen: 0,
  listEdequityInvestmentSize: 0,
  listedEquityInvestmentFrequency: 0,
  understandingTradingRisksShares: '',
  equityFundsTradedBefore: '',
  equityFundsWhen: 0,
  equityFundsInvestmentSize: 0,
  equityFundsInvestmentFrequency: 0,
  understandingTradingRiskIncomeFund: '',
  mixedFundsBroadDiscretionaryManagementTradedBefore: '',
  mixFundBroadDiscManagWhen: 0,
  mixFundBroadBiscManagInvestSize: 0,
  mixfundBroaddiscManagInvestFreq: 0,
  understandingTradingRiskMFoundBdm: '',
  certificateTradedWithBefore: '',
  certificateWhen: 0,
  certificateInvestmentSize: 0,
  certificateInvestmentFrequency: 0,
  understandRisksTradingCertificate: '',
  etftTadedWithBefore: '',
  etfWhen: 0,
  etfInvestmentSize: 0,
  etfInvestmentFrequency: 0,
  understandRisksTradingEtf: '',
  alternativeFundsHedgeFundsTradedPreviously: '',
  alternativeFundHedgFundWhen: 0,
  alternativeFundHedgeFundInvestSize: 0,
  alternativeFundHedgeFundInvestFreq: 0,
  understandTradingRiskAlterHedgeF: '',
  incomeFundsTradedBefore: '',
  incomeFundsWhen: 0,
  incomeFundsInvestmentFrequency: 0,
  incomeFundsInvestmentsSize: 0,

  interestBearingInstrumentsTradedPreviously: '',
  interestBearingInstrumentsWhen: 0,
  interestBearingInstrumentInvestSiz: 0,
  interestBearingInstrumentInvFreq: 0,
  understandTradeRiskInterestBearing: '',

  coeliPrivateEquityTradedBefore: '',
  understandTradingRiskPrivateEquity: '',
  
  coeliRealEstateTradedBefore: '',
  unlistedEquitiesTradedPreviously: 0,
  unlistedEquitiesTrainedByAdvisors: '',
  understandingUnlistedEquitiesLoseAllCapital: '',
  understandingUnlistedEquitiesTransferRestrictionsInUnlistedShares: '',
  understandingUnlistedEquitiesPerformanceBasedFeesMayAffectValue: '',
  understandingUnlistedEquitiesUnlistedCompanies: '',
  understandingUnlistedEquitiesUnlistedshares: '',
  understandingUnlistedEquitiesInvestmentSuitableForSale: '',
  assetCustomerDoesNotWantInvested: '',
  understandPrivEquityAdvTrainedMeValue: undefined,
  understandRealestateAdvTrainedMeValue: undefined,
  coeliPrivateEquityWhen: 0,
  coeliPrivateEquityInvestmentSize: 0,
  coeliPrivateEquityInvestmentFreq: 0,
  coeliRealestateTradedBefore: '',
  coeliRealstateWhen: 0,
  coeliRealEstateInvestSize: 0,
  coelirealestateInvestFrequence: 0,
  understandRisksTradCoeliRealestate: '',
  unlistedEquitiesWhen: 0,
  unlistedEquitiesInvestmentsSize: 0,
  unlistedEquitiesInvestmentFreq: 0,
  summaryExperienceAndTraining: '',
  // whatDoesRiskMeanFortheInvestor: 0,
  // riskScenarioIndividualYear: 0,
  // riskScenario10years: 0,
  investRisk: 0,
  investRiskAction: 0,
  annualTargetReturn: 0,
  investmentAlternativetoBankAccount: '',
  investmentWithAllocationStrategy: '',
  investmentPppListedandUnlisted: '',
  summaryRiskAssessment: '',

  assetTypeWhichDontWant: '',
  assetTypeWhichValue: undefined,
  acceptanceCanNegAffectReturnsRisk: '',
  summaryInvestmentPreferences: '',
  specifyWhy: '',

  recommendationAmountInterestFunds: '',
  recommendationAmoListEquityEquityF: '',
  recommendationamoPrivEquityFund: '',
  recommendationAmoAlterinvestHedge: '',
  recommendationAmoRealestateFund: '',
  recommendationAmoListUnlinvesCase: '',
  advisorRecomRiskLevelforCustomer: 0,
  summaryofOverallManagementStrategy: '',

  birthday: '',
  jobTitle: '',
  representativeName: '',
  representativeSocialSecurityNumber: '',
  idCopyValidity: '',
  companyName: '',
  contactClassification: '',
  assestDepositOrTransfer: '',
  investmentPurpose: '',
  avarageInvestment: '',
  deposityFrequency: '',
  beneficialOwnerPep: false,
  citizenshipInAnotherCountry: false,
  connectionToUSA: false,
  contactIdOnFile: false,
  familyMemberPEP: false,
  isLivingInAnotherCountry: false,
  otherBeneficialOwner: false,
  pep: false,
  relationshipToCustomer: '',
  representativeId: false,
  taxREsidencyOtherThanSweeden: false,
  doYouHaveaSustainabilityPreference: undefined,
  interestSustainableInvestments: undefined,
  greenhouseGasesEmission: undefined,
  carbonFootprint: undefined,
  greenhouseGasEmissionsCompany: undefined,
  fossilFuel: undefined,
  nonRenewableEnergy: undefined,
  energyConsumption: undefined,
  releaseIntoWater: undefined,
  hazardousWaste: undefined,
  violationGlobalAgreement: undefined,
  deficiencyGlobalAgreement: undefined,
  controversialWeapons: undefined,
  genderPayGap: undefined,
  genderDistribution: undefined,
  interestProducts: undefined,
  allocationsAssetsHighLiquidityDec: undefined,
  allocationsAssetsLimitedLiquidityDec: undefined,
  allowedDriftOnOverallRecommendedAllocation: undefined,
};

function InvestorProfileUserForm(props: any) {
  const IsToDo = props.isToDo;
  const dispatch = useAppDispatch();
  const users = useAppSelector((state) => state.users);
  const api = useApiClient();
  const { t } = useTranslation();

  //confirm
  function FormDialogConfirm(props: any) {
    const [open, setOpen] = React.useState(false);

    const { values } = useFormikContext();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (isSubmit: boolean) => {
      if (isSubmit) {
        props.handleIsFetch(false);
        const activeUserId = getActiveUser(users)?.id;
        const mainUserId = getMainUser(users)?.id;
        const ip = values as IInvestorProfile;
        ip.statuscode = 'Confirmed';
        api
          .post('api/InvestorProfile/update', {
            ...ip,
            approverId: mainUserId,
            ownerId: activeUserId,
          })
          .then((res) => {
            props.handleDataChanged(!props.dataChangd);
            if (IsToDo) {
              dispatch(setToDoComplete(ToDoEnum.InvestorProfile));
            }
          });
      }

      setOpen(false);
    };

    return (
      <>
        <Button variant="outlined" color="primary" onClick={handleClickOpen}>
          {t('Confirm')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{t('LegalNote')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('investorProfile.dialog.ConfirmText')}
              <br />
              <Link
                href="https://coeli.se/legal-information-coeli-wealth-management-ab/"
                target={'_blank'}
              >
                https://coeli.se/legal-information-coeli-wealth-management-ab/
              </Link>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={() => handleClose(true)}>
              {t('Confirm')}
            </Button>
            <Button variant="outlined" onClick={() => handleClose(false)}>
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  //reject
  function FormDialogReject(props: any) {
    const [open, setOpen] = React.useState(false);
    const [rejectComment, setRejectComment] = React.useState<string>('');

    const { values } = useFormikContext();

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = (isSubmit: boolean) => {
      if (isSubmit) {
        props.handleIsFetch(false);
        const activeUserId = getActiveUser(users)?.id;
        const mainUserId = getMainUser(users)?.id;
        const ip = values as IInvestorProfile;
        ip.statuscode = 'Rejected';
        api
          .post('api/InvestorProfile/update', {
            ...ip,
            approverId: mainUserId,
            ownerId: activeUserId,
            rejectComment: rejectComment,
          })
          .then((res) => {
            props.handleDataChanged(!props.dataChangd);
            if (IsToDo) {
              dispatch(setToDoComplete(ToDoEnum.InvestorProfile));
            }
          });
      }
      setOpen(false);
    };

    return (
      <>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleClickOpen}
          sx={{ marginLeft: '1em' }}
        >
          {t('Reject')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>{t('LegalNote')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('investorProfile.dialog.RejectText')}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="comment"
              label="Rejection reason"
              fullWidth
              multiline
              minRows={4}
              variant="outlined"
              value={rejectComment}
              onChange={(e) => setRejectComment(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={rejectComment === '' ? true : false}
              variant="outlined"
              onClick={() => handleClose(true)}
            >
              {t('Reject')}
            </Button>
            <Button variant="outlined" onClick={() => handleClose(false)}>
              {t('Cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  function BaseForm() {
    const activeUserCanSign = getActiveUser(users)?.canSign;

    const [isFetched, setIsFetched] = useState(false);
    const [dataChanged, setDataChanged] = useState(false);
    const isDisabled = true;
    const [isConfirmed, setIsConfirmed] = useState(true);

    const yesNoOptions = [
      { label: t('Yes'), value: true },
      { label: t('No'), value: false },
    ];

    const familySituation = [
      { label: t('investorProfile.familySituation.Single'), value: 963440000 },
      { label: t('investorProfile.familySituation.Married'), value: 963440001 },
      { label: t('investorProfile.familySituation.Partner'), value: 963440002 },
      {
        label: t('investorProfile.familySituation.PartnerNotLivingTogether'),
        value: 963440003,
      },
    ];

    const childrenCountOptions = [
      { label: '0', value: 963440006 },
      { label: '1', value: 963440000 },
      { label: '2', value: 963440001 },
      { label: '3', value: 963440002 },
      { label: '4', value: 963440003 },
      { label: '5', value: 963440004 },
      { label: t('investorProfile.MoreThan5'), value: 963440005 },
    ];

    const childrenMinorCountOptions = [
      { label: '0', value: 963440000 },
      { label: '1', value: 963440001 },
      { label: '2', value: 963440002 },
      { label: '3', value: 963440003 },
      { label: '4', value: 963440004 },
      { label: '5', value: 963440005 },
      { label: t('investorProfile.MoreThan5'), value: 963440006 },
    ];

    const retirement = [
      { value: 963440000, label: t('investorProfile.retirement.Option1') },
      { value: 963440001, label: t('investorProfile.retirement.Option2') },
      { value: 963440003, label: t('investorProfile.retirement.Option3') },
      { value: 963440002, label: t('investorProfile.retirement.Option4') },
    ];

    const investmentObjectivesDescription = [
      {
        value: 963440000,
        label: t(
          'investorProfile.investmentObjectivesDescriptions.NextGenerations'
        ),
      },
      {
        value: 963440001,
        label: t(
          'investorProfile.investmentObjectivesDescriptions.SecureOwnPension'
        ),
      },
      {
        value: 963440002,
        label: t(
          'investorProfile.investmentObjectivesDescriptions.ReturnOnCapital'
        ),
      },
      {
        value: 963440003,
        label: t(
          'investorProfile.investmentObjectivesDescriptions.SpecificInvestmentGoals'
        ),
      },
    ];

    const investmentHorizon = [
      {
        value: 963440000,
        label: t('investorProfile.investmentHorizon.Option1'),
      },
      {
        value: 963440001,
        label: t('investorProfile.investmentHorizon.Option2'),
      },
      {
        value: 963440002,
        label: t('investorProfile.investmentHorizon.Option3'),
      },
      {
        value: 963440003,
        label: t('investorProfile.investmentHorizon.Option4'),
      },
      {
        value: 963440004,
        label: t('investorProfile.investmentHorizon.Option5'),
      },
    ];

    const listedEquityWhen = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];

    const listedEquityInvestmentSize = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];

    const listedEquityInvestmentFrequency = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const equityFundsWhen = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];
    const equityFundsInvestmentSize = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];
    const equityFundsInvestmentFrequency = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const mixFundBroadDiscManagWhen = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];
    const mixFundBroadBiscManagInvestSize = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];
    const mixfundBroaddiscManagInvestFreq = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const alternativeFundHedgFundWhen = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];
    const alternativeFundHedgeFundInvestSize = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];
    const alternativeFundHedgeFundInvestFreq = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const incomeFundsWhen = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];
    const incomeFundsInvestmentsSize = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];
    const incomeFundsInvestmentFrequency = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const interestBearingInstrumentsWhen = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];
    const interestBearingInstrumentInvestSiz = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];
    const interestBearingInstrumentInvFreq = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const coeliPrivateEquityWhen = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];
    const coeliPrivateEquityInvestmentSize = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];
    const coeliPrivateEquityInvestmentFreq = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const coeliRealstateWhen = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];
    const coeliRealEstateInvestSize = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];
    const coelirealestateInvestFrequence = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const unlistedEquitiesWhen = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];
    const unlistedEquitiesInvestmentsSize = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];
    const unlistedEquitiesInvestmentFreq = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const investorProfileWhen = [
      { value: 963440000, label: t('investorProfile.LessThan3YearsAgo') },
      { value: 963440001, label: t('investorProfile.MoreThan3YearsAgo') },
    ];
    const investorProfileInvestmentsSize = [
      { value: 963440000, label: t('investorProfile.0to300000sek') },
      { value: 963440001, label: t('investorProfile.MoreThan300000sek') },
    ];
    const investorProfileInvestmentFreq = [
      { value: 963440000, label: t('investorProfile.LessThan6Occasions') },
      { value: 963440001, label: t('investorProfile.MoreThan6Occasions') },
    ];

    const unlistedEquitiesTradedPreviously = [
      { value: 963440000, label: t('Yes') },
      {
        value: 963440001,
        label: t(
          'investorProfile.unlistedEquitiesTradedPreviously.YesOwnedCompany'
        ),
      },
      {
        value: 963440002,
        label: t(
          'investorProfile.unlistedEquitiesTradedPreviously.NoInterested'
        ),
      },
      {
        value: 963440003,
        label: t(
          'investorProfile.unlistedEquitiesTradedPreviously.NoNotRelevant'
        ),
      },
    ];

    // const whatDoesRiskMeanFortheInvestor = [
    //   {
    //     value: 963440000,
    //     label: t('investorProfile.whatDoesRiskMeanFortheInvestor.Option1'),
    //   },
    //   {
    //     value: 963440001,
    //     label: t('investorProfile.whatDoesRiskMeanFortheInvestor.Option2'),
    //   },
    // ];

    // const riskScenarioIndividualYear = [
    //   {
    //     value: 963440000,
    //     label: t('investorProfile.riskScenarioIndividualYear.Option1'),
    //   },
    //   {
    //     value: 963440001,
    //     label: t('investorProfile.riskScenarioIndividualYear.Option2'),
    //   },
    //   {
    //     value: 963440002,
    //     label: t('investorProfile.riskScenarioIndividualYear.Option3'),
    //   },
    // ];

    // const riskScenario10years = [
    //   {
    //     value: 963440000,
    //     label: t('investorProfile.riskScenario10years.Option1'),
    //   },
    //   {
    //     value: 963440001,
    //     label: t('investorProfile.riskScenario10years.Option2'),
    //   },
    //   {
    //     value: 963440002,
    //     label: t('investorProfile.riskScenario10years.Option3'),
    //   },
    // ];

    const investRisk = [
      {
        value: 963440000,
        label: t('investorProfile.investRiskOptions.Option1'),
      },
      {
        value: 963440001,
        label: t('investorProfile.investRiskOptions.Option2'),
      },
      {
        value: 963440002,
        label: t('investorProfile.investRiskOptions.Option3'),
      },
      {
        value: 963440003,
        label: t('investorProfile.investRiskOptions.Option4'),
      },
      {
        value: 963440004,
        label: t('investorProfile.investRiskOptions.Option5'),
      },
      {
        value: 963440005,
        label: t('investorProfile.investRiskOptions.Option6'),
      },
      {
        value: 963440006,
        label: t('investorProfile.investRiskOptions.Option7'),
      },
    ];

    const investRiskAction = [
      {
        value: 963440000,
        label: t('investorProfile.investRiskActionOptions.Option1'),
      },
      {
        value: 963440001,
        label: t('investorProfile.investRiskActionOptions.Option2'),
      },
      {
        value: 963440002,
        label: t('investorProfile.investRiskActionOptions.Option3'),
      },
      {
        value: 963440003,
        label: t('investorProfile.investRiskActionOptions.Option4'),
      },
      {
        value: 963440004,
        label: t('investorProfile.investRiskActionOptions.Option5'),
      },
      {
        value: 963440005,
        label: t('investorProfile.investRiskActionOptions.Option6'),
      },
      {
        value: 963440006,
        label: t('investorProfile.investRiskActionOptions.Option7'),
      },
    ];

    const annualTargetReturn = [
      { value: 963440000, label: '1 - 3 %' },
      { value: 963440001, label: '3 - 5 %' },
      { value: 963440002, label: '5 - 8 %' },
      { value: 963440003, label: '8 - 12 %' },
      { value: 963440004, label: t('investorProfile.MoreThan12%') },
    ];

    const advisorRecomRiskLevelforCustomer = [
      { value: 963440005, label: t('Bankaccount') },
      { value: 963440000, label: t('Low') },
      { value: 963440001, label: t('Medium-Low') },
      { value: 963440002, label: t('Medium') },
      { value: 963440003, label: t('Medium-High') },
      { value: 963440004, label: t('High') },
      { value: 963440006, label: t('Speculation') },
    ];

    const investmentPrefrencesPercentages = [
      { value: 963440010, label: '10 %' },
      { value: 963440020, label: '20 %' },
      { value: 963440030, label: '30 %' },
      { value: 963440040, label: '40 %' },
      { value: 963440050, label: '50 %' },
      { value: 963440060, label: '60 %' },
      { value: 963440070, label: '70 %' },
      { value: 963440080, label: '80 %' },
      { value: 963440090, label: '90 %' },
      { value: 963440100, label: '100 %' },
      { value: 963440000, label: t('investorProfile.NoPreferences') },
    ];

    const handleSubmit = async (
      values: any,
      { resetForm, setErrors, setStatus, setSubmitting }: any
    ) => {};

    useEffect(() => {
      const activeUserSSN = getActiveUser(users)?.socialSecurityNumber;

      setIsFetched(false);
      api.get(`/api/InvestorProfile/${activeUserSSN}`).then((res) => {
        let ip: IInvestorProfile = res.data;
        if (ip) {
          initialValues = { ...(ip as IInvestorProfile) };
          if (ip.statuscode === 'Confirmed') {
            setIsConfirmed(true);
          } else {
            if (activeUserCanSign) {
              setIsConfirmed(false);
            } else {
              setIsConfirmed(true);
            }
          }
        } else {
          initialValues = {} as IInvestorProfile;
          setIsConfirmed(true);
        }
        setTimeout(() => {
          setIsFetched(true);
        }, 500);
      });
    }, [users, activeUserCanSign]);

    return (
      <div>
        {isFetched ? (
          <Formik initialValues={{ ...initialValues }} onSubmit={handleSubmit}>
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
              status,
            }) => (
              <Card>
                <CardContent>
                  {isSubmitting ? (
                    <Box display="flex" justifyContent="center" my={6}>
                      <CircularProgress />
                    </Box>
                  ) : values.id ? (
                    <form>
                      <Typography variant="h6" gutterBottom>
                        {t('menu.InvestorProfile')}
                      </Typography>
                      <Typography display="inline">
                        {t('ResponsibleCoeli')}
                        {': '}
                      </Typography>
                      <Typography
                        variant="body2"
                        display="inline"
                        fontWeight={'bold'}
                      >
                        {values.ownerName}
                      </Typography>

                      {isConfirmed ? null : (
                        <Grid container mt={2}>
                          <Grid item>
                            <FormDialogConfirm
                              handleDataChanged={setDataChanged}
                              handleIsFetch={setIsFetched}
                            ></FormDialogConfirm>
                          </Grid>
                          <Grid item>
                            <FormDialogReject
                              handleDataChanged={setDataChanged}
                              handleIsFetch={setIsFetched}
                            ></FormDialogReject>
                          </Grid>
                        </Grid>
                      )}
                      <Grid container spacing={6} mt={2}>
                        <Grid item>
                          <Typography variant="h6" gutterBottom>
                            {t('investorProfile.Headline1')}
                          </Typography>
                          <Typography fontStyle={'italic'}>
                            {t('investorProfile.Headline1Desc')}
                          </Typography>
                        </Grid>
                        {/* Investor category */}
                        <Grid item xs={12} mt={2}>
                          <Typography variant="h6" gutterBottom>
                            {t('investorProfile.CustomerCategory')}
                          </Typography>
                          <Typography fontStyle={'italic'}>
                            {t('investorProfile.CustomerCategoryDesc')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} mt={2}>
                          <FormControl component="fieldset">
                            <FormLabel component="legend">
                              {t('investorProfile.InvestorCategory')}
                            </FormLabel>
                            <RadioGroup
                              aria-label="investorCategory"
                              name="investorCategory"
                              value={values.investorCategory}
                              onChange={handleChange}
                            >
                              <FormControlLabel
                                value={InvestorCategory.NonProfessional}
                                disabled={isDisabled}
                                control={<Radio />}
                                label={t('investorProfile.NonProfessional')}
                              />
                              <FormControlLabel
                                value={InvestorCategory.Professional}
                                disabled={isDisabled}
                                control={<Radio />}
                                label={t('investorProfile.Professional')}
                              />
                              <FormControlLabel
                                value={InvestorCategory.ComparableCounterparty}
                                disabled={isDisabled}
                                control={<Radio />}
                                label={t(
                                  'investorProfile.ComparableCounterparty'
                                )}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>

                        {values.investorCategory ===
                        InvestorCategory.NonProfessional ? (
                          <>
                            <Grid item xs={12} mt={4}>
                              <Typography variant="h6" gutterBottom>
                                {t(
                                  'investorProfile.FamilySituationAndEmployment'
                                )}
                              </Typography>
                            </Grid>

                            {/* Employment */}
                            <Grid item xs={12} mt={4}>
                              <FormControl
                                sx={{ m: 3 }}
                                component="fieldset"
                                variant="standard"
                              >
                                <FormLabel component="legend">
                                  {t('investorProfile.Employment')}
                                </FormLabel>
                                <FormGroup>
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.employmentEmployed}
                                        disabled={isDisabled}
                                        onChange={handleChange}
                                      />
                                    }
                                    label={t('investorProfile.Employed')}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.employmentEntrepreneur}
                                        disabled={isDisabled}
                                        onChange={handleChange}
                                      />
                                    }
                                    label={t('investorProfile.Entrepreneur')}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.employmentRetired}
                                        disabled={isDisabled}
                                        onChange={handleChange}
                                      />
                                    }
                                    label={t('investorProfile.Retired')}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.employmentLiveOfWealth}
                                        disabled={isDisabled}
                                        onChange={handleChange}
                                      />
                                    }
                                    label={t('investorProfile.LiveOfWealth')}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.employmentStudent}
                                        disabled={isDisabled}
                                        onChange={handleChange}
                                      />
                                    }
                                    label={t('investorProfile.Student')}
                                  />
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={values.employmentUnemployed}
                                        disabled={isDisabled}
                                        onChange={handleChange}
                                      />
                                    }
                                    label={t('investorProfile.Unemployed')}
                                  />
                                </FormGroup>
                              </FormControl>
                            </Grid>

                            {/* Family situation */}
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="familySituation"
                                component={RadioGroupFormField}
                                options={familySituation}
                                isDisabled={isDisabled}
                                label={t('investorProfile.FamilySituation')}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="children"
                                component={RadioGroupFormField}
                                options={childrenCountOptions}
                                isDisabled={isDisabled}
                                label={t('investorProfile.Children')}
                              />
                            </Grid>

                            {values.children !== 963440006 /* 0 children */ ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="noOfMinorsChildrenFinancialResponsibilities"
                                    component={RadioGroupFormField}
                                    options={childrenMinorCountOptions}
                                    isDisabled={isDisabled}
                                    label={t(
                                      'investorProfile.NumberChildrenResponsibilities'
                                    )}
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12}>
                              <TextField
                                name="summaryFamilySituationEmployment"
                                label={t(
                                  'investorProfile.SummaryFamAndEmployment'
                                )}
                                value={
                                  values.summaryFamilySituationEmployment || ''
                                }
                                error={Boolean(
                                  touched.summaryFamilySituationEmployment &&
                                    errors.summaryFamilySituationEmployment
                                )}
                                fullWidth
                                multiline
                                minRows={4}
                                helperText={
                                  touched.summaryFamilySituationEmployment &&
                                  errors.summaryFamilySituationEmployment
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Typography variant="h6" gutterBottom>
                                {t('investorProfile.IncomeExpensesAndAssets')}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="incomeEmploymentAndPensionMonthAfterTax"
                                label={t('investorProfile.IncomeFrom')}
                                value={
                                  values.incomeEmploymentAndPensionMonthAfterTax ||
                                  ''
                                }
                                error={Boolean(
                                  touched.incomeEmploymentAndPensionMonthAfterTax &&
                                    errors.incomeEmploymentAndPensionMonthAfterTax
                                )}
                                fullWidth
                                helperText={
                                  touched.incomeEmploymentAndPensionMonthAfterTax &&
                                  errors.incomeEmploymentAndPensionMonthAfterTax
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="annualIncomeFromCapital"
                                label={t(
                                  'investorProfile.AnnualIncomeFromCapital'
                                )}
                                value={values.annualIncomeFromCapital || ''}
                                error={Boolean(
                                  touched.annualIncomeFromCapital &&
                                    errors.annualIncomeFromCapital
                                )}
                                fullWidth
                                helperText={
                                  touched.annualIncomeFromCapital &&
                                  errors.annualIncomeFromCapital
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="monthlyCost"
                                label={t('investorProfile.MonthlyCosts')}
                                value={values.monthlyCost || ''}
                                error={Boolean(
                                  touched.monthlyCost && errors.monthlyCost
                                )}
                                fullWidth
                                helperText={
                                  touched.monthlyCost && errors.monthlyCost
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="plannedRetirement"
                                component={RadioGroupFormField}
                                options={retirement}
                                isDisabled={isDisabled}
                                label={t('investorProfile.PlannedRetirement')}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsCash"
                                label={t('investorProfile.ExternalAssetsCash')}
                                value={values.externalAssetsCash || ''}
                                error={Boolean(
                                  touched.externalAssetsCash &&
                                    errors.externalAssetsCash
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsCash &&
                                  errors.externalAssetsCash
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsEquity"
                                label={t(
                                  'investorProfile.ExternalAssetsEquity'
                                )}
                                value={values.externalAssetsEquity || ''}
                                error={Boolean(
                                  touched.externalAssetsEquity &&
                                    errors.externalAssetsEquity
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsEquity &&
                                  errors.externalAssetsEquity
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsInterestFunds"
                                label={t(
                                  'investorProfile.ExternalAssetsInterestFunds'
                                )}
                                value={values.externalAssetsInterestFunds || ''}
                                error={Boolean(
                                  touched.externalAssetsInterestFunds &&
                                    errors.externalAssetsInterestFunds
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsInterestFunds &&
                                  errors.externalAssetsInterestFunds
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsBonds"
                                label={t('investorProfile.ExternalAssetsBonds')}
                                value={values.externalAssetsBonds || ''}
                                error={Boolean(
                                  touched.externalAssetsBonds &&
                                    errors.externalAssetsBonds
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsBonds &&
                                  errors.externalAssetsBonds
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsEquityFunds"
                                label={t(
                                  'investorProfile.ExternalAssetsEquityFunds'
                                )}
                                value={values.externalAssetsEquityFunds || ''}
                                error={Boolean(
                                  touched.externalAssetsEquityFunds &&
                                    errors.externalAssetsEquityFunds
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsEquityFunds &&
                                  errors.externalAssetsEquityFunds
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsAlternativInvestments"
                                label={t(
                                  'investorProfile.ExternalAssetsAlternativInvestments'
                                )}
                                value={
                                  values.externalAssetsAlternativInvestments ||
                                  ''
                                }
                                error={Boolean(
                                  touched.externalAssetsAlternativInvestments &&
                                    errors.externalAssetsAlternativInvestments
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsAlternativInvestments &&
                                  errors.externalAssetsAlternativInvestments
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsMixtFundsOrDisk"
                                label={t(
                                  'investorProfile.ExternalAssetsMixtFundsOrDisk'
                                )}
                                value={
                                  values.externalAssetsMixtFundsOrDisk || ''
                                }
                                error={Boolean(
                                  touched.externalAssetsMixtFundsOrDisk &&
                                    errors.externalAssetsMixtFundsOrDisk
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsMixtFundsOrDisk &&
                                  errors.externalAssetsMixtFundsOrDisk
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsPrivateEquity"
                                label={t(
                                  'investorProfile.ExternalAssetsPrivateEquity'
                                )}
                                value={values.externalAssetsPrivateEquity || ''}
                                error={Boolean(
                                  touched.externalAssetsPrivateEquity &&
                                    errors.externalAssetsPrivateEquity
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsPrivateEquity &&
                                  errors.externalAssetsPrivateEquity
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsRealEstate"
                                label={t(
                                  'investorProfile.ExternalAssetsRealEstate'
                                )}
                                value={values.externalAssetsRealEstate || ''}
                                error={Boolean(
                                  touched.externalAssetsRealEstate &&
                                    errors.externalAssetsRealEstate
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsRealEstate &&
                                  errors.externalAssetsRealEstate
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsUnlistedEquity"
                                label={t(
                                  'investorProfile.ExternalAssetsUnlistedEquity'
                                )}
                                value={
                                  values.externalAssetsUnlistedEquity || ''
                                }
                                error={Boolean(
                                  touched.externalAssetsUnlistedEquity &&
                                    errors.externalAssetsUnlistedEquity
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsUnlistedEquity &&
                                  errors.externalAssetsUnlistedEquity
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="externalAssetsOwnedCompanies"
                                label={t(
                                  'investorProfile.ExternalAssetsOwnedCompanies'
                                )}
                                value={
                                  values.externalAssetsOwnedCompanies || ''
                                }
                                error={Boolean(
                                  touched.externalAssetsOwnedCompanies &&
                                    errors.externalAssetsOwnedCompanies
                                )}
                                fullWidth
                                helperText={
                                  touched.externalAssetsOwnedCompanies &&
                                  errors.externalAssetsOwnedCompanies
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="pensionAssets"
                                label={t('investorProfile.PensionAssets')}
                                value={values.pensionAssets || ''}
                                error={Boolean(
                                  touched.pensionAssets && errors.pensionAssets
                                )}
                                fullWidth
                                helperText={
                                  touched.pensionAssets && errors.pensionAssets
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="realEstateValue"
                                label={t('investorProfile.RealEstateValue')}
                                value={values.realEstateValue || ''}
                                error={Boolean(
                                  touched.realEstateValue &&
                                    errors.realEstateValue
                                )}
                                fullWidth
                                helperText={
                                  touched.realEstateValue &&
                                  errors.realEstateValue
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="realEstateLoans"
                                label={t('investorProfile.RealEstateLoans')}
                                value={values.realEstateLoans || ''}
                                error={Boolean(
                                  touched.realEstateLoans &&
                                    errors.realEstateLoans
                                )}
                                fullWidth
                                helperText={
                                  touched.realEstateLoans &&
                                  errors.realEstateLoans
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="otherLoans"
                                label={t('investorProfile.OtherLoans')}
                                value={values.otherLoans || ''}
                                error={Boolean(
                                  touched.otherLoans && errors.otherLoans
                                )}
                                fullWidth
                                helperText={
                                  touched.otherLoans && errors.otherLoans
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.AssessedRiskDiscrepancyClientAdviser'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="assessedRiskDiscrepancyClientAdviser"
                                  name="assessedRiskDiscrepancyClientAdviser"
                                  value={
                                    values.assessedRiskDiscrepancyClientAdviser
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.assessedRiskDiscrepancyClientAdviser ===
                            'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="riskAssessmentDiscrepancyDescription"
                                    label={t(
                                      'investorProfile.RiskAssessmentDiscrepancyDescription'
                                    )}
                                    value={
                                      values.riskAssessmentDiscrepancyDescription ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.riskAssessmentDiscrepancyDescription &&
                                        errors.riskAssessmentDiscrepancyDescription
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.riskAssessmentDiscrepancyDescription &&
                                      errors.riskAssessmentDiscrepancyDescription
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.ExpectedChangeAssetsAndLiabilities'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="customerPEP"
                                  name="expectedChangeAssetsAndLiabilities"
                                  value={
                                    values.expectedChangeAssetsAndLiabilities
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.expectedChangeAssetsAndLiabilities ===
                            'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="whatAreExpectedChangeInAssetsAndLiabilities"
                                    label={t(
                                      'investorProfile.WhatAreExpectedChangeInAssetsAndLiabilities'
                                    )}
                                    value={
                                      values.whatAreExpectedChangeInAssetsAndLiabilities ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.whatAreExpectedChangeInAssetsAndLiabilities &&
                                        errors.whatAreExpectedChangeInAssetsAndLiabilities
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.whatAreExpectedChangeInAssetsAndLiabilities &&
                                      errors.whatAreExpectedChangeInAssetsAndLiabilities
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.ExpectedChangesRevenueAndExpenses'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="expectedChangesRevenueAndExpenses"
                                  name="expectedChangesRevenueAndExpenses"
                                  value={
                                    values.expectedChangesRevenueAndExpenses
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.expectedChangesRevenueAndExpenses ===
                            'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="whatAreExpectedChangesRevenueAndExpenses"
                                    label={t(
                                      'investorProfile.WhatAreExpectedChangesRevenueAndExpenses'
                                    )}
                                    value={
                                      values.whatAreExpectedChangesRevenueAndExpenses ||
                                      ''
                                    }
                                    error={Boolean(
                                      touched.whatAreExpectedChangesRevenueAndExpenses &&
                                        errors.whatAreExpectedChangesRevenueAndExpenses
                                    )}
                                    fullWidth
                                    helperText={
                                      touched.whatAreExpectedChangesRevenueAndExpenses &&
                                      errors.whatAreExpectedChangesRevenueAndExpenses
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="summaryOfIncomeExpensesAndAssets"
                                label={t(
                                  'investorProfile.SummaryOfIncomeExpensesAndAssets'
                                )}
                                value={
                                  values.summaryOfIncomeExpensesAndAssets || ''
                                }
                                error={Boolean(
                                  touched.summaryOfIncomeExpensesAndAssets &&
                                    errors.summaryOfIncomeExpensesAndAssets
                                )}
                                fullWidth
                                multiline
                                minRows={4}
                                helperText={
                                  touched.summaryOfIncomeExpensesAndAssets &&
                                  errors.summaryOfIncomeExpensesAndAssets
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Typography variant="h6" gutterBottom>
                                {t('investorProfile.InvestmentObjectives')}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="investmentObjectives"
                                component={RadioGroupFormField}
                                options={investmentObjectivesDescription}
                                isDisabled={isDisabled}
                                label={t(
                                  'investorProfile.InvestmentObjectivesDescription'
                                )}
                              />
                            </Grid>

                            {values.investmentObjectives ===
                            963440003 /*'Specific investment goals'*/ ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="investmentGoalsDescribe"
                                    label={t(
                                      'investorProfile.InvestmentGoalsDescribe'
                                    )}
                                    value={values.investmentGoalsDescribe || ''}
                                    error={Boolean(
                                      touched.investmentGoalsDescribe &&
                                        errors.investmentGoalsDescribe
                                    )}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    helperText={
                                      touched.investmentGoalsDescribe &&
                                      errors.investmentGoalsDescribe
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="investmentHorizon"
                                component={RadioGroupFormField}
                                options={investmentHorizon}
                                isDisabled={isDisabled}
                                label={t('investorProfile.InvestmentHorizon')}
                              />
                            </Grid>

                            {values.investmentHorizon === 963440004 ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="investmentHorizonDescribe"
                                    label={t(
                                      'investorProfile.InvestmentHorizonDescribe'
                                    )}
                                    value={
                                      values.investmentHorizonDescribe || ''
                                    }
                                    error={Boolean(
                                      touched.investmentHorizonDescribe &&
                                        errors.investmentHorizonDescribe
                                    )}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    helperText={
                                      touched.investmentHorizonDescribe &&
                                      errors.investmentHorizonDescribe
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.DependentOnCapitalShortTerm'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="customerPEP"
                                  name="dependentOnCapitalShortTerm"
                                  value={values.dependentOnCapitalShortTerm}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.dependentOnCapitalShortTerm === 'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="annualCapitalDependency1to3y"
                                    label={t(
                                      'investorProfile.AnnualCapitalDependency1to3y'
                                    )}
                                    value={
                                      values.annualCapitalDependency1to3y || ''
                                    }
                                    error={Boolean(
                                      touched.annualCapitalDependency1to3y &&
                                        errors.annualCapitalDependency1to3y
                                    )}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    helperText={
                                      touched.annualCapitalDependency1to3y &&
                                      errors.annualCapitalDependency1to3y
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled}
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="nnualCapitalDependency3to5y"
                                    label={t(
                                      'investorProfile.AnnualCapitalDependency3to5y'
                                    )}
                                    value={
                                      values.annualCapitalDependency3to5y || ''
                                    }
                                    error={Boolean(
                                      touched.annualCapitalDependency3to5y &&
                                        errors.annualCapitalDependency3to5y
                                    )}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    helperText={
                                      touched.annualCapitalDependency3to5y &&
                                      errors.annualCapitalDependency3to5y
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled}
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                      {t(
                                        'investorProfile.DependentOnCapitalLongTerm'
                                      )}
                                    </FormLabel>
                                    <RadioGroup
                                      aria-label="dependentOnCapitalLongTerm"
                                      name="dependentOnCapitalLongTerm"
                                      value={values.dependentOnCapitalLongTerm}
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        disabled={isDisabled}
                                        value="true"
                                        control={<Radio />}
                                        label={t('Yes')}
                                      />
                                      <FormControlLabel
                                        disabled={isDisabled}
                                        value="false"
                                        control={<Radio />}
                                        label={t('No')}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>

                                {values.dependentOnCapitalLongTerm ===
                                'true' ? (
                                  <>
                                    <Grid item xs={12} mt={4}>
                                      <TextField
                                        name="annualcapitaldependence5to10y"
                                        label={t(
                                          'investorProfile.Annualcapitaldependence5to10y'
                                        )}
                                        value={
                                          values.annualcapitaldependence5to10y ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.annualcapitaldependence5to10y &&
                                            errors.annualcapitaldependence5to10y
                                        )}
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        helperText={
                                          touched.annualcapitaldependence5to10y &&
                                          errors.annualcapitaldependence5to10y
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={isDisabled}
                                      />
                                    </Grid>

                                    <Grid item xs={12} mt={4}>
                                      <TextField
                                        name="annualcapitaldependencymore10y"
                                        label={t(
                                          'investorProfile.Annualcapitaldependencymore10y'
                                        )}
                                        value={
                                          values.annualcapitaldependencymore10y ||
                                          ''
                                        }
                                        error={Boolean(
                                          touched.annualcapitaldependencymore10y &&
                                            errors.annualcapitaldependencymore10y
                                        )}
                                        fullWidth
                                        multiline
                                        minRows={4}
                                        helperText={
                                          touched.annualcapitaldependencymore10y &&
                                          errors.annualcapitaldependencymore10y
                                        }
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={isDisabled}
                                      />
                                    </Grid>
                                  </>
                                ) : null}
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="investmentSummaryObjectivesHorizon"
                                label={t(
                                  'investorProfile.InvestmentSummaryObjectivesHorizon'
                                )}
                                value={
                                  values.investmentSummaryObjectivesHorizon ||
                                  ''
                                }
                                error={Boolean(
                                  touched.investmentSummaryObjectivesHorizon &&
                                    errors.investmentSummaryObjectivesHorizon
                                )}
                                fullWidth
                                multiline
                                minRows={4}
                                helperText={
                                  touched.investmentSummaryObjectivesHorizon &&
                                  errors.investmentSummaryObjectivesHorizon
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Typography variant="h6" gutterBottom>
                                {t('investorProfile.Experience')}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('investorProfile.Experience')} -{' '}
                                  {t('investorProfile.Profession')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="experienceProfession"
                                  name="experienceProfession"
                                  value={values.experienceProfession}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.experienceProfession === 'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="professionWhich"
                                    label={
                                      t('investorProfile.Profession') +
                                      ' - ' +
                                      t('investorProfile.Which')
                                    }
                                    value={values.professionWhich}
                                    error={Boolean(
                                      touched.professionWhich &&
                                        errors.professionWhich
                                    )}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    helperText={
                                      touched.professionWhich &&
                                      errors.professionWhich
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('investorProfile.Experience')} -{' '}
                                  {t('investorProfile.Education')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="customerPEP"
                                  name="experienceEducation"
                                  value={values.experienceEducation}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.experienceEducation === 'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <TextField
                                    name="educationWhich"
                                    label={
                                      t('investorProfile.Education') +
                                      ' - ' +
                                      t('investorProfile.Which')
                                    }
                                    value={values.educationWhich || ''}
                                    error={Boolean(
                                      touched.educationWhich &&
                                        errors.educationWhich
                                    )}
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    helperText={
                                      touched.educationWhich &&
                                      errors.educationWhich
                                    }
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled}
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('investorProfile.ListedEquity')} -{' '}
                                  {t('investorProfile.TradedPreviously')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="listedEquityTradedPreviously"
                                  name="listedEquityTradedPreviously"
                                  value={values.listedEquityTradedPreviously}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.listedEquityTradedPreviously === 'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="listedEquityWhen"
                                    component={RadioGroupFormField}
                                    options={listedEquityWhen}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.ListedEquity') +
                                      ' - ' +
                                      t('investorProfile.When')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="listEdequityInvestmentSize"
                                    component={RadioGroupFormField}
                                    options={listedEquityInvestmentSize}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.ListedEquity') +
                                      ' - ' +
                                      t('investorProfile.InvestmentSize')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="listedEquityInvestmentFrequency"
                                    component={RadioGroupFormField}
                                    options={listedEquityInvestmentFrequency}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.ListedEquity') +
                                      ' - ' +
                                      t('investorProfile.InvestmentFrequency')
                                    }
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.UnderstandingTradingRisksShares'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="understandingTradingRisksShares"
                                  name="understandingTradingRisksShares"
                                  value={values.understandingTradingRisksShares}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('investorProfile.EquityFunds')} -{' '}
                                  {t('investorProfile.TradedPreviously')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="equityFundsTradedBefore"
                                  name="equityFundsTradedBefore"
                                  value={values.equityFundsTradedBefore}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.equityFundsTradedBefore === 'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="equityFundsWhen"
                                    component={RadioGroupFormField}
                                    options={equityFundsWhen}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.EquityFunds') +
                                      ' - ' +
                                      t('investorProfile.When')
                                    }
                                  />{' '}
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="equityFundsInvestmentSize"
                                    component={RadioGroupFormField}
                                    options={equityFundsInvestmentSize}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.EquityFunds') +
                                      ' - ' +
                                      t('investorProfile.InvestmentSize')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="equityFundsInvestmentFrequency"
                                    component={RadioGroupFormField}
                                    options={equityFundsInvestmentFrequency}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.EquityFunds') +
                                      ' - ' +
                                      t('investorProfile.InvestmentFrequency')
                                    }
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.UnderstandingTradingRiskEquityFunds'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="understandingTradingRiskEquityFunds"
                                  name="understandingTradingRiskIncomeFund"
                                  value={
                                    values.understandingTradingRiskIncomeFund
                                  } // Should be understandingTradingRiskquityFunds ??
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.MixedFundsBroadDiscretionaryManagement'
                                  )}{' '}
                                  - {t('investorProfile.TradedPreviously')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="mixedFundsBroadDiscretionaryManagementTradedBefore"
                                  name="mixedFundsBroadDiscretionaryManagementTradedBefore"
                                  value={
                                    values.mixedFundsBroadDiscretionaryManagementTradedBefore
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.mixedFundsBroadDiscretionaryManagementTradedBefore ===
                            'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="mixFundBroadDiscManagWhen"
                                    component={RadioGroupFormField}
                                    options={mixFundBroadDiscManagWhen}
                                    isDisabled={isDisabled}
                                    label={
                                      t(
                                        'investorProfile.MixedFundsBroadDiscretionaryManagement'
                                      ) +
                                      ' - ' +
                                      t('investorProfile.When')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="mixFundBroadBiscManagInvestSize"
                                    component={RadioGroupFormField}
                                    options={mixFundBroadBiscManagInvestSize}
                                    isDisabled={isDisabled}
                                    label={
                                      t(
                                        'investorProfile.MixedFundsBroadDiscretionaryManagement'
                                      ) +
                                      ' - ' +
                                      t('investorProfile.InvestmentSize')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="mixfundBroaddiscManagInvestFreq"
                                    component={RadioGroupFormField}
                                    options={mixfundBroaddiscManagInvestFreq}
                                    isDisabled={isDisabled}
                                    label={
                                      t(
                                        'investorProfile.MixedFundsBroadDiscretionaryManagement'
                                      ) +
                                      ' - ' +
                                      t('investorProfile.InvestmentFrequency')
                                    }
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.UnderstandingTradingRiskMFoundBdm'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="understandingTradingRiskMFoundBdm"
                                  name="understandingTradingRiskMFoundBdm"
                                  value={
                                    values.understandingTradingRiskMFoundBdm
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('investorProfile.Certificate')} -{' '}
                                  {t('investorProfile.TradedPreviously')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="certificateTradedWithBefore"
                                  name="certificateTradedWithBefore"
                                  value={values.certificateTradedWithBefore}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.certificateTradedWithBefore === 'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="certificateWhen"
                                    component={RadioGroupFormField}
                                    options={investorProfileWhen}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.Certificate') +
                                      ' - ' +
                                      t('investorProfile.When')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="certificateInvestmentSize"
                                    component={RadioGroupFormField}
                                    options={investorProfileInvestmentsSize}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.Certificate') +
                                      ' - ' +
                                      t('investorProfile.InvestmentSize')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="certificateInvestmentFrequency"
                                    component={RadioGroupFormField}
                                    options={investorProfileInvestmentFreq}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.Certificate') +
                                      ' - ' +
                                      t('investorProfile.InvestmentFrequency')
                                    }
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.UnderstandRisksTradingCertificate'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="understandRisksTradingCertificate"
                                  name="understandRisksTradingCertificate"
                                  value={
                                    values.understandRisksTradingCertificate
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('investorProfile.Etf')} -{' '}
                                  {t('investorProfile.TradedPreviously')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="etftTadedWithBefore"
                                  name="etftTadedWithBefore"
                                  value={values.etftTadedWithBefore}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.etftTadedWithBefore === 'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="etfWhen"
                                    component={RadioGroupFormField}
                                    options={investorProfileWhen}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.Etf') +
                                      ' - ' +
                                      t('investorProfile.When')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="etfInvestmentSize"
                                    component={RadioGroupFormField}
                                    options={investorProfileInvestmentsSize}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.Etf') +
                                      ' - ' +
                                      t('investorProfile.InvestmentSize')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="etfInvestmentFrequency"
                                    component={RadioGroupFormField}
                                    options={investorProfileInvestmentFreq}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.Etf') +
                                      ' - ' +
                                      t('investorProfile.InvestmentFrequency')
                                    }
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.UnderstandRisksTradingEtf'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="understandRisksTradingEtf"
                                  name="understandRisksTradingEtf"
                                  value={values.understandRisksTradingEtf}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.AlternativeFundsHedgeFunds'
                                  )}{' '}
                                  - {t('investorProfile.TradedPreviously')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="alternativeFundsHedgeFundsTradedPreviously"
                                  name="alternativeFundsHedgeFundsTradedPreviously"
                                  value={
                                    values.alternativeFundsHedgeFundsTradedPreviously
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.alternativeFundsHedgeFundsTradedPreviously ===
                            'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="alternativeFundHedgFundWhen"
                                    component={RadioGroupFormField}
                                    options={alternativeFundHedgFundWhen}
                                    isDisabled={isDisabled}
                                    label={
                                      t(
                                        'investorProfile.AlternativeFundsHedgeFunds'
                                      ) +
                                      ' - ' +
                                      t('investorProfile.When')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="alternativeFundHedgeFundInvestSize"
                                    component={RadioGroupFormField}
                                    options={alternativeFundHedgeFundInvestSize}
                                    isDisabled={isDisabled}
                                    label={
                                      t(
                                        'investorProfile.AlternativeFundsHedgeFunds'
                                      ) +
                                      ' - ' +
                                      t('investorProfile.InvestmentSize')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="alternativeFundHedgeFundInvestFreq"
                                    component={RadioGroupFormField}
                                    options={alternativeFundHedgeFundInvestFreq}
                                    isDisabled={isDisabled}
                                    label={
                                      t(
                                        'investorProfile.AlternativeFundsHedgeFunds'
                                      ) +
                                      ' - ' +
                                      t('investorProfile.InvestmentFrequency')
                                    }
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.UnderstandTradingRiskAlterHedgeF'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="understandTradingRiskAlterHedgeF"
                                  name="understandTradingRiskAlterHedgeF"
                                  value={
                                    values.understandTradingRiskAlterHedgeF
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('investorProfile.IncomeFunds')} -{' '}
                                  {t('investorProfile.TradedPreviously')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="customerPEP"
                                  name="incomeFundsTradedBefore"
                                  value={values.incomeFundsTradedBefore}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.UnderstandingTradingRiskIncomeFund'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="understandingTradingRiskIncomeFund"
                                  name="understandingTradingRiskIncomeFund"
                                  value={
                                    values.understandingTradingRiskIncomeFund
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.InterestBearingInstruments'
                                  )}{' '}
                                  - {t('investorProfile.TradedPreviously')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="interestBearingInstrumentsTradedPreviously"
                                  name="interestBearingInstrumentsTradedPreviously"
                                  value={
                                    values.interestBearingInstrumentsTradedPreviously
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.interestBearingInstrumentsTradedPreviously ===
                            'true' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="interestBearingInstrumentsWhen"
                                    component={RadioGroupFormField}
                                    options={interestBearingInstrumentsWhen}
                                    isDisabled={isDisabled}
                                    label={
                                      t(
                                        'investorProfile.InterestBearingInstruments'
                                      ) +
                                      ' - ' +
                                      t('investorProfile.When')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="interestBearingInstrumentInvestSiz"
                                    component={RadioGroupFormField}
                                    options={interestBearingInstrumentInvestSiz}
                                    isDisabled={isDisabled}
                                    label={
                                      t(
                                        'investorProfile.InterestBearingInstruments'
                                      ) +
                                      ' - ' +
                                      t('investorProfile.InvestmentSize')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="interestBearingInstrumentInvFreq"
                                    component={RadioGroupFormField}
                                    options={interestBearingInstrumentInvFreq}
                                    isDisabled={isDisabled}
                                    label={
                                      t(
                                        'investorProfile.InterestBearingInstruments'
                                      ) +
                                      ' - ' +
                                      t('investorProfile.InvestmentFrequency')
                                    }
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.UnderstandTradeRiskInterestBearing'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="understandTradeRiskInterestBearing"
                                  name="understandTradeRiskInterestBearing"
                                  value={
                                    values.understandTradeRiskInterestBearing
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('investorProfile.CoeliPrivateEquity')} -{' '}
                                  {t('investorProfile.TradedPreviously')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="coeliPrivateEquityTradedBefore"
                                  name="coeliPrivateEquityTradedBefore"
                                  value={values.coeliPrivateEquityTradedBefore}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.coeliPrivateEquityTradedBefore ===
                            'false' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <FormControl
                                    sx={{ m: 2 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormLabel component="legend">
                                      {t(
                                        'investorProfile.UnderstanTradeRiskCoeliPrivateEquity'
                                      )}
                                    </FormLabel>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.understandPrivEquityAdvTrainedMeValue?.some(
                                              (p) =>
                                                p ===
                                                understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.ThatInvestmentsAreAadeIndirectlyInANumberOfUnlistedShares
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.ThatInvestmentsAreAadeIndirectlyInANumberOfUnlistedShares'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.understandPrivEquityAdvTrainedMeValue?.some(
                                              (p) =>
                                                p ===
                                                understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.ThatTheShareMayHaveALimitedLiquidityBasedOnTurnover
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.ThatTheShareMayHaveALimitedLiquidityBasedOnTurnover'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.understandPrivEquityAdvTrainedMeValue?.some(
                                              (p) =>
                                                p ===
                                                understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.TheShareIsSuitableForTheLongTermInvestor
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.TheShareIsSuitableForTheLongTermInvestor'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.understandPrivEquityAdvTrainedMeValue?.some(
                                              (p) =>
                                                p ===
                                                understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.ThereIsAConflictOfInterest
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.ThereIsAConflictOfInterest'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.understandPrivEquityAdvTrainedMeValue?.some(
                                              (p) =>
                                                p ===
                                                understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.TheInvestmentIsNotRelevantToMe
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.TheInvestmentIsNotRelevantToMe'
                                        )}
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="coeliPrivateEquityWhen"
                                component={RadioGroupFormField}
                                options={coeliPrivateEquityWhen}
                                isDisabled={isDisabled}
                                label={
                                  t('investorProfile.CoeliPrivateEquity') +
                                  ' - ' +
                                  t('investorProfile.When')
                                }
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="coeliPrivateEquityInvestmentSize"
                                component={RadioGroupFormField}
                                options={coeliPrivateEquityInvestmentSize}
                                isDisabled={isDisabled}
                                label={
                                  t('investorProfile.CoeliPrivateEquity') +
                                  ' - ' +
                                  t('investorProfile.InvestmentSize')
                                }
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="coeliPrivateEquityInvestmentFreq"
                                component={RadioGroupFormField}
                                options={coeliPrivateEquityInvestmentFreq}
                                isDisabled={isDisabled}
                                label={
                                  t('investorProfile.CoeliPrivateEquity') +
                                  ' - ' +
                                  t('investorProfile.InvestmentFrequency')
                                }
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.UnderstandTradeRiskPrivateEquity'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="understandTradeRiskPrivateEquity"
                                  name="understandTradeRiskPrivateEquity"
                                  value={
                                    values.understandTradingRiskPrivateEquity
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t('investorProfile.CoeliRealEstate')} -{' '}
                                  {t('investorProfile.TradedPreviously')}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="coeliRealEstateTradedBefore"
                                  name="coeliRealEstateTradedBefore"
                                  value={values.coeliRealEstateTradedBefore}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            {values.coeliRealEstateTradedBefore === 'false' ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <FormControl
                                    sx={{ m: 2 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormLabel component="legend">
                                      {t(
                                        'investorProfile.UnderstandTradeRiskCoeliRealEstateAdvisor'
                                      )}
                                    </FormLabel>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.understandRealestateAdvTrainedMeValue?.some(
                                              (p) =>
                                                p ===
                                                understandingCoeliRealEstateAdvisorHasTrainedMeTo.ThatInvestmentsAreAadeIndirectlyInANumberOfProperties
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.ThatInvestmentsAreAadeIndirectlyInANumberOfProperties'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.understandRealestateAdvTrainedMeValue?.some(
                                              (p) =>
                                                p ===
                                                understandingCoeliPrivateEquityAdvisorHasTrainedMeTo.ThatTheShareMayHaveALimitedLiquidityBasedOnTurnover
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.ThatTheShareMayHaveALimitedLiquidityBasedOnTurnover'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.understandRealestateAdvTrainedMeValue?.some(
                                              (p) =>
                                                p ===
                                                understandingCoeliRealEstateAdvisorHasTrainedMeTo.TheShareIsSuitableForTheLongTermInvestor
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.TheShareIsSuitableForTheLongTermInvestor'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.understandRealestateAdvTrainedMeValue?.some(
                                              (p) =>
                                                p ===
                                                understandingCoeliRealEstateAdvisorHasTrainedMeTo.ThereIsAConflictOfInterest
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.ThereIsAConflictOfInterest'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.understandRealestateAdvTrainedMeValue?.some(
                                              (p) =>
                                                p ===
                                                understandingCoeliRealEstateAdvisorHasTrainedMeTo.TheInvestmentIsNotRelevantToMe
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.TheInvestmentIsNotRelevantToMe'
                                        )}
                                      />
                                    </FormGroup>
                                  </FormControl>
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="coeliRealstateWhen"
                                component={RadioGroupFormField}
                                options={coeliRealstateWhen}
                                isDisabled={isDisabled}
                                label={
                                  t('investorProfile.CoeliRealEstate') +
                                  ' - ' +
                                  t('investorProfile.When')
                                }
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="coeliRealEstateInvestSize"
                                component={RadioGroupFormField}
                                options={coeliRealEstateInvestSize}
                                isDisabled={isDisabled}
                                label={
                                  t('investorProfile.CoeliRealEstate') +
                                  ' - ' +
                                  t('investorProfile.InvestmentSize')
                                }
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="coelirealestateInvestFrequence"
                                component={RadioGroupFormField}
                                options={coelirealestateInvestFrequence}
                                isDisabled={isDisabled}
                                label={
                                  t('investorProfile.CoeliRealEstate') +
                                  ' - ' +
                                  t('investorProfile.InvestmentFrequency')
                                }
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.UnderstanTradeRiskCoeliRealEstate'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="understandRisksTradCoeliRealestate"
                                  name="understandRisksTradCoeliRealestate"
                                  value={
                                    values.understandRisksTradCoeliRealestate
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="unlistedEquitiesTradedPreviously"
                                component={RadioGroupFormField}
                                options={unlistedEquitiesTradedPreviously}
                                isDisabled={isDisabled}
                                label={
                                  t('investorProfile.UnlistedEquities') +
                                  ' - ' +
                                  t('investorProfile.TradedPreviously')
                                }
                              />
                            </Grid>

                            {values.unlistedEquitiesTradedPreviously ===
                              UnlistedEquitiesTradedPreviouslyOptions.Yes ||
                            values.unlistedEquitiesTradedPreviously ===
                              UnlistedEquitiesTradedPreviouslyOptions.YesOwnedOrPartlyOwnedCompanies ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="unlistedEquitiesWhen"
                                    component={RadioGroupFormField}
                                    options={unlistedEquitiesWhen}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.UnlistedEquities') +
                                      ' - ' +
                                      t('investorProfile.When')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="unlistedEquitiesInvestmentsSize"
                                    component={RadioGroupFormField}
                                    options={unlistedEquitiesInvestmentsSize}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.UnlistedEquities') +
                                      ' - ' +
                                      t('investorProfile.InvestmentSize')
                                    }
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="unlistedEquitiesInvestmentFreq"
                                    component={RadioGroupFormField}
                                    options={unlistedEquitiesInvestmentFreq}
                                    isDisabled={isDisabled}
                                    label={
                                      t('investorProfile.UnlistedEquities') +
                                      ' - ' +
                                      t('investorProfile.InvestmentFrequency')
                                    }
                                  />
                                </Grid>
                              </>
                            ) : null}

                            {values.unlistedEquitiesTradedPreviously ===
                            UnlistedEquitiesTradedPreviouslyOptions.NoInterested ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                      {t('investorProfile.UnlistedEquities') +
                                        ' - ' +
                                        t('investorProfile.TrainedByAdvisors')}
                                    </FormLabel>
                                    <RadioGroup
                                      aria-label="unlistedEquitiesTrainedByAdvisors"
                                      name="unlistedEquitiesTrainedByAdvisors"
                                      value={
                                        values.unlistedEquitiesTrainedByAdvisors
                                      }
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        disabled={isDisabled}
                                        value="true"
                                        control={<Radio />}
                                        label={t('Yes')}
                                      />
                                      <FormControlLabel
                                        disabled={isDisabled}
                                        value="false"
                                        control={<Radio />}
                                        label={t('No')}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </>
                            ) : null}

                            {values.unlistedEquitiesTradedPreviously ===
                              UnlistedEquitiesTradedPreviouslyOptions.Yes ||
                            values.unlistedEquitiesTradedPreviously ===
                              UnlistedEquitiesTradedPreviouslyOptions.YesOwnedOrPartlyOwnedCompanies ||
                            (values.unlistedEquitiesTradedPreviously ===
                              UnlistedEquitiesTradedPreviouslyOptions.NoInterested &&
                              values.unlistedEquitiesTrainedByAdvisors ===
                                'true') ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                      {t(
                                        'investorProfile.UnderstandingUnlistedEquities'
                                      )}
                                    </FormLabel>
                                    <Typography sx={{ fontStyle: 'italic' }}>
                                      {t(
                                        'investorProfile.UnderstandingUnlistedEquitiesLoseAllCapital'
                                      )}
                                    </Typography>
                                    <RadioGroup
                                      aria-label="understandingUnlistedEquitiesLoseAllCapital"
                                      name="understandingUnlistedEquitiesLoseAllCapital"
                                      value={
                                        values.understandingUnlistedEquitiesLoseAllCapital
                                      }
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        disabled={isDisabled}
                                        value="true"
                                        control={<Radio />}
                                        label={t('Yes')}
                                      />
                                      <FormControlLabel
                                        disabled={isDisabled}
                                        value="false"
                                        control={<Radio />}
                                        label={t('No')}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>

                                {values.understandingUnlistedEquitiesLoseAllCapital ===
                                'true' ? (
                                  <>
                                    <Grid item xs={12} mt={4}>
                                      <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                          {t(
                                            'investorProfile.UnderstandingUnlistedEquities'
                                          )}
                                        </FormLabel>
                                        <Typography
                                          sx={{ fontStyle: 'italic' }}
                                        >
                                          {t(
                                            'investorProfile.UnderstandingUnlistedEquitiesTransferRestrictionsInUnlistedShares'
                                          )}
                                        </Typography>
                                        <RadioGroup
                                          aria-label="understandingUnlistedEquitiesTransferRestrictionsInUnlistedShares"
                                          name="understandingUnlistedEquitiesTransferRestrictionsInUnlistedShares"
                                          value={
                                            values.understandingUnlistedEquitiesTransferRestrictionsInUnlistedShares
                                          }
                                          onChange={handleChange}
                                        >
                                          <FormControlLabel
                                            disabled={isDisabled}
                                            value="true"
                                            control={<Radio />}
                                            label={t('Yes')}
                                          />
                                          <FormControlLabel
                                            disabled={isDisabled}
                                            value="false"
                                            control={<Radio />}
                                            label={t('No')}
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>

                                    {values.understandingUnlistedEquitiesTransferRestrictionsInUnlistedShares ===
                                    'true' ? (
                                      <>
                                        <Grid item xs={12} mt={4}>
                                          <FormControl component="fieldset">
                                            <FormLabel component="legend">
                                              {t(
                                                'investorProfile.UnderstandingUnlistedEquities'
                                              )}
                                            </FormLabel>
                                            <Typography
                                              sx={{ fontStyle: 'italic' }}
                                            >
                                              {t(
                                                'investorProfile.UnderstandingUnlistedEquitiesPerformanceBasedFeesMayAffectValue'
                                              )}
                                            </Typography>
                                            <RadioGroup
                                              aria-label="understandingUnlistedEquitiesPerformanceBasedFeesMayAffectValue"
                                              name="understandingUnlistedEquitiesPerformanceBasedFeesMayAffectValue"
                                              value={
                                                values.understandingUnlistedEquitiesPerformanceBasedFeesMayAffectValue
                                              }
                                              onChange={handleChange}
                                            >
                                              <FormControlLabel
                                                disabled={isDisabled}
                                                value="true"
                                                control={<Radio />}
                                                label={t('Yes')}
                                              />
                                              <FormControlLabel
                                                disabled={isDisabled}
                                                value="false"
                                                control={<Radio />}
                                                label={t('No')}
                                              />
                                            </RadioGroup>
                                          </FormControl>
                                        </Grid>

                                        {values.understandingUnlistedEquitiesPerformanceBasedFeesMayAffectValue ===
                                        'true' ? (
                                          <>
                                            <Grid item xs={12} mt={4}>
                                              <FormControl component="fieldset">
                                                <FormLabel component="legend">
                                                  {t(
                                                    'investorProfile.UnderstandingUnlistedEquities'
                                                  )}
                                                </FormLabel>
                                                <Typography
                                                  sx={{ fontStyle: 'italic' }}
                                                >
                                                  {t(
                                                    'investorProfile.UnderstandingUnlistedEquitiesUnlistedCompanies'
                                                  )}
                                                </Typography>
                                                <RadioGroup
                                                  aria-label="understandingUnlistedEquitiesUnlistedCompanies"
                                                  name="understandingUnlistedEquitiesUnlistedCompanies"
                                                  value={
                                                    values.understandingUnlistedEquitiesUnlistedCompanies
                                                  }
                                                  onChange={handleChange}
                                                >
                                                  <FormControlLabel
                                                    disabled={isDisabled}
                                                    value="true"
                                                    control={<Radio />}
                                                    label={t('Yes')}
                                                  />
                                                  <FormControlLabel
                                                    disabled={isDisabled}
                                                    value="false"
                                                    control={<Radio />}
                                                    label={t('No')}
                                                  />
                                                </RadioGroup>
                                              </FormControl>
                                            </Grid>

                                            {values.understandingUnlistedEquitiesUnlistedCompanies ===
                                            'true' ? (
                                              <>
                                                <Grid item xs={12} mt={4}>
                                                  <FormControl component="fieldset">
                                                    <FormLabel component="legend">
                                                      {t(
                                                        'investorProfile.UnderstandingUnlistedEquities'
                                                      )}
                                                    </FormLabel>
                                                    <Typography
                                                      sx={{
                                                        fontStyle: 'italic',
                                                      }}
                                                    >
                                                      {t(
                                                        'investorProfile.UnderstandingUnlistedEquitiesUnlistedshares'
                                                      )}
                                                    </Typography>
                                                    <RadioGroup
                                                      aria-label="understandingUnlistedEquitiesUnlistedshares"
                                                      name="understandingUnlistedEquitiesUnlistedshares"
                                                      value={
                                                        values.understandingUnlistedEquitiesUnlistedshares
                                                      }
                                                      onChange={handleChange}
                                                    >
                                                      <FormControlLabel
                                                        disabled={isDisabled}
                                                        value="true"
                                                        control={<Radio />}
                                                        label={t('Yes')}
                                                      />
                                                      <FormControlLabel
                                                        disabled={isDisabled}
                                                        value="false"
                                                        control={<Radio />}
                                                        label={t('No')}
                                                      />
                                                    </RadioGroup>
                                                  </FormControl>
                                                </Grid>

                                                {values.understandingUnlistedEquitiesUnlistedshares ===
                                                'true' ? (
                                                  <>
                                                    <Grid item xs={12} mt={4}>
                                                      <FormControl component="fieldset">
                                                        <FormLabel component="legend">
                                                          {t(
                                                            'investorProfile.UnderstandingUnlistedEquities'
                                                          )}
                                                        </FormLabel>
                                                        <Typography
                                                          sx={{
                                                            fontStyle: 'italic',
                                                          }}
                                                        >
                                                          {t(
                                                            'investorProfile.UnderstandingUnlistedEquitiesInvestmentSuitableForSale'
                                                          )}
                                                        </Typography>
                                                        <RadioGroup
                                                          aria-label="understandingUnlistedEquitiesInvestmentSuitableForSale"
                                                          name="understandingUnlistedEquitiesInvestmentSuitableForSale"
                                                          value={
                                                            values.understandingUnlistedEquitiesInvestmentSuitableForSale
                                                          }
                                                          onChange={
                                                            handleChange
                                                          }
                                                        >
                                                          <FormControlLabel
                                                            disabled={
                                                              isDisabled
                                                            }
                                                            value="true"
                                                            control={<Radio />}
                                                            label={t('Yes')}
                                                          />
                                                          <FormControlLabel
                                                            disabled={
                                                              isDisabled
                                                            }
                                                            value="false"
                                                            control={<Radio />}
                                                            label={t('No')}
                                                          />
                                                        </RadioGroup>
                                                      </FormControl>
                                                    </Grid>
                                                  </>
                                                ) : null}
                                              </>
                                            ) : null}
                                          </>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </>
                                ) : null}

                                {values.understandingUnlistedEquitiesInvestmentSuitableForSale ===
                                  '' ||
                                values.understandingUnlistedEquitiesInvestmentSuitableForSale ===
                                  'false' ? (
                                  <>
                                    <Grid item xs={12} mt={4}>
                                      <FormControl component="fieldset">
                                        <FormLabel component="legend">
                                          {t(
                                            'investorProfile.LacksUnderstandingOfUnlistedEquities'
                                          )}
                                        </FormLabel>
                                        <Typography
                                          sx={{ fontStyle: 'italic' }}
                                        >
                                          {t(
                                            'investorProfile.TrainForUnlistedEquities'
                                          )}
                                        </Typography>
                                        <RadioGroup
                                          aria-label="customerPEP"
                                          name="customerPEP"
                                          //value={values.customerPEP}
                                          onChange={handleChange}
                                        >
                                          <FormControlLabel
                                            disabled={isDisabled}
                                            value="true"
                                            control={<Radio />}
                                            label={t('Yes')}
                                          />
                                          <FormControlLabel
                                            disabled={isDisabled}
                                            value="false"
                                            control={<Radio />}
                                            label={t('No')}
                                          />
                                        </RadioGroup>
                                      </FormControl>
                                    </Grid>
                                  </>
                                ) : null}
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="summaryExperienceAndTraining"
                                label={t(
                                  'investorProfile.SummaryExperienceAndTraining'
                                )}
                                value={
                                  values.summaryExperienceAndTraining || ''
                                }
                                error={Boolean(
                                  touched.summaryExperienceAndTraining &&
                                    errors.summaryExperienceAndTraining
                                )}
                                fullWidth
                                multiline
                                minRows={4}
                                helperText={
                                  touched.summaryExperienceAndTraining &&
                                  errors.summaryExperienceAndTraining
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Typography variant="h6" gutterBottom>
                                {t('investorProfile.RiskProfileAssessment')}
                              </Typography>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="investRisk"
                                component={RadioGroupFormField}
                                options={investRisk}
                                isDisabled={isDisabled}
                                label={t('investorProfile.InvestRisk')}
                              />
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="investRiskAction"
                                component={RadioGroupFormField}
                                options={investRiskAction}
                                isDisabled={isDisabled}
                                label={t('investorProfile.investRiskAction')}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="annualTargetReturn"
                                component={RadioGroupFormField}
                                options={annualTargetReturn}
                                isDisabled={isDisabled}
                                label={t('investorProfile.AnnualTargetReturn')}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.InvestmentAlternativetoBankAccount'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="investmentAlternativetoBankAccount"
                                  name="investmentAlternativetoBankAccount"
                                  value={
                                    values.investmentAlternativetoBankAccount
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.InvestmentWithAllocationStrategy'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="investmentWithAllocationStrategy"
                                  name="investmentWithAllocationStrategy"
                                  value={
                                    values.investmentWithAllocationStrategy
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.InvestmentPppListedandUnlisted'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="investmentPppListedandUnlisted"
                                  name="investmentPppListedandUnlisted"
                                  value={values.investmentPppListedandUnlisted}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="summaryRiskAssessment"
                                label={t(
                                  'investorProfile.SummaryRiskAssessment'
                                )}
                                value={values.summaryRiskAssessment || ''}
                                error={Boolean(
                                  touched.summaryRiskAssessment &&
                                    errors.summaryRiskAssessment
                                )}
                                fullWidth
                                multiline
                                minRows={4}
                                helperText={
                                  touched.summaryRiskAssessment &&
                                  errors.summaryRiskAssessment
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Typography variant="h6" gutterBottom>
                                {t('investorProfile.InvestmentPreferences')}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} mt={4}>
                              <Field
                                name="doYouHaveaSustainabilityPreference"
                                component={RadioGroupFormField}
                                options={yesNoOptions}
                                isDisabled={isDisabled}
                                label={t(
                                  'investorProfile.DoYouHaveaSustainabilityPreference'
                                )}
                              />
                            </Grid>

                            {values.doYouHaveaSustainabilityPreference ===
                            true ? (
                              <>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t(
                                      'investorProfile.InvestmentPrefrencesSustainableInvestments'
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t(
                                      'investorProfile.InvestmentPrefrencesSustainableInvestmentsDesc'
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="interestSustainableInvestments"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label={t(
                                      'investorProfile.ParticularInterestSustainableInvestments'
                                    )}
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t(
                                      'investorProfile.InvestmentPrefrencesNegativeSustainability'
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {' '}
                                    {t(
                                      'investorProfile.InvestmentPrefrencesNegativeSustainabilityDesc'
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t(
                                      'investorProfile.InvestmentPrefrencesNegativeConsequencesEnvironment'
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t(
                                      'investorProfile.InvestmentPrefrencesNegativeConsequencesEnvironmentDesc'
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t(
                                      'investorProfile.GreenhouseGasesEmission'
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t(
                                      'investorProfile.GreenhouseGasesEmissionDesc'
                                    )}
                                  </Typography>
                                  <Field
                                    name="greenhouseGasesEmission"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t('investorProfile.CarbonFootprint')}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {' '}
                                    {t('investorProfile.CarbonFootprintDesc')}
                                  </Typography>
                                  <Field
                                    name="carbonFootprint"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {' '}
                                    {t(
                                      'investorProfile.GreenhouseGasEmissionsCompany'
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t(
                                      'investorProfile.GreenhouseGasEmissionsCompanyDesc'
                                    )}
                                  </Typography>
                                  <Field
                                    name="greenhouseGasEmissionsCompany"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t('investorProfile.FossilFuel')}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t('investorProfile.FossilFuelDesc')}
                                  </Typography>
                                  <Field
                                    name="fossilFuel"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t('investorProfile.NonRenewableEnergy')}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t(
                                      'investorProfile.NonRenewableEnergyDesc'
                                    )}
                                  </Typography>
                                  <Field
                                    name="nonRenewableEnergy"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t('investorProfile.EnergyConsumption')}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t('investorProfile.EnergyConsumptionDesc')}
                                  </Typography>
                                  <Field
                                    name="energyConsumption"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t('investorProfile.ReleaseIntoWater')}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t('investorProfile.ReleaseIntoWaterDesc')}
                                  </Typography>
                                  <Field
                                    name="releaseIntoWater"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t('investorProfile.HazardousWaste')}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t('investorProfile.HazardousWasteDesc')}
                                  </Typography>
                                  <Field
                                    name="hazardousWaste"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t(
                                      'investorProfile.InvestmentPrefrencesNegativeConsequencesSocialSustainability'
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t(
                                      'investorProfile.InvestmentPrefrencesNegativeConsequencesSocialSustainabilityDesc'
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t(
                                      'investorProfile.ViolationGlobalAgreement'
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t(
                                      'investorProfile.ViolationGlobalAgreementDesc'
                                    )}
                                  </Typography>
                                  <Field
                                    name="violationGlobalAgreement"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {' '}
                                    {t(
                                      'investorProfile.DeficiencyGlobalAgreement'
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t(
                                      'investorProfile.DeficiencyGlobalAgreementDesc'
                                    )}
                                  </Typography>
                                  <Field
                                    name="deficiencyGlobalAgreement"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {' '}
                                    {t('investorProfile.ControversialWeapons')}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t(
                                      'investorProfile.ControversialWeaponsDesc'
                                    )}
                                  </Typography>
                                  <Field
                                    name="controversialWeapons"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t('investorProfile.GenderPayGap')}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t('investorProfile.GenderPayGapDesc')}
                                  </Typography>
                                  <Field
                                    name="genderPayGap"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t('investorProfile.GenderDistribution')}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {t(
                                      'investorProfile.GenderDistributionDesc'
                                    )}
                                  </Typography>
                                  <Field
                                    name="genderDistribution"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label=""
                                  />
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <Typography>
                                    {t(
                                      'investorProfile.InvestmentPrefrencesInvestedProductsSpecialEnvironmental'
                                    )}
                                  </Typography>
                                  <Typography sx={{ fontStyle: 'italic' }}>
                                    {' '}
                                    {t(
                                      'investorProfile.InvestmentPrefrencesInvestedProductsSpecialEnvironmentalDesc'
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12} mt={4}>
                                  <Field
                                    name="interestProducts"
                                    component={RadioGroupFormField}
                                    options={investmentPrefrencesPercentages}
                                    isDisabled={isDisabled}
                                    label={t(
                                      'investorProfile.InterestProducts'
                                    )}
                                  />
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <FormControl component="fieldset">
                                <FormLabel component="legend">
                                  {t(
                                    'investorProfile.AssetCustomerDoesNotWantInvested'
                                  )}
                                </FormLabel>
                                <RadioGroup
                                  aria-label="assetCustomerDoesNotWantInvested"
                                  name="assetCustomerDoesNotWantInvested"
                                  value={
                                    values.assetCustomerDoesNotWantInvested
                                  }
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="true"
                                    control={<Radio />}
                                    label={t('Yes')}
                                  />
                                  <FormControlLabel
                                    disabled={isDisabled}
                                    value="false"
                                    control={<Radio />}
                                    label={t('No')}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            {values.assetCustomerDoesNotWantInvested ===
                            'true' ? (
                              <>
                                <Grid item xs={12} mt={0}>
                                  <FormControl
                                    sx={{ m: 2 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormLabel component="legend">
                                      {t('investorProfile.Which_2')}
                                    </FormLabel>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.assetTypeWhichValue?.some(
                                              (p) =>
                                                p ===
                                                witchdoesntwant.ListedEquties
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.ListedEquity'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.assetTypeWhichValue?.some(
                                              (p) =>
                                                p ===
                                                witchdoesntwant.EquityFunds
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t('investorProfile.EquityFunds')}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.assetTypeWhichValue?.some(
                                              (p) =>
                                                p === witchdoesntwant.HedgeFunds
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.AlternativeFundsHedgeFunds'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.assetTypeWhichValue?.some(
                                              (p) =>
                                                p ===
                                                witchdoesntwant.InterestFunds
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.InterestFunds'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.assetTypeWhichValue?.some(
                                              (p) =>
                                                p ===
                                                witchdoesntwant.PrivateEquityFunds
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.PrivateEquityFunds'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.assetTypeWhichValue?.some(
                                              (p) =>
                                                p ===
                                                witchdoesntwant.RealEstateFunds
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.RealEstateFunds'
                                        )}
                                      />
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            checked={values.assetTypeWhichValue?.some(
                                              (p) =>
                                                p ===
                                                witchdoesntwant.UnlistedEquities
                                            )}
                                            onChange={handleChange}
                                            name="operatingIncome"
                                          />
                                        }
                                        disabled={isDisabled}
                                        label={t(
                                          'investorProfile.UnlistedEquities'
                                        )}
                                      />
                                    </FormGroup>
                                  </FormControl>
                                  <Grid item xs={12} mt={4}>
                                    <TextField
                                      name="specifyWhy"
                                      label={t('investorProfile.SpecifyWhy')}
                                      value={values.specifyWhy || ''}
                                      error={Boolean(
                                        touched.specifyWhy && errors.specifyWhy
                                      )}
                                      fullWidth
                                      helperText={
                                        touched.specifyWhy && errors.specifyWhy
                                      }
                                      onBlur={handleBlur}
                                      onChange={handleChange}
                                      variant="outlined"
                                      disabled={isDisabled}
                                    />
                                  </Grid>
                                </Grid>

                                <Grid item xs={12} mt={4}>
                                  <FormControl component="fieldset">
                                    <FormLabel component="legend">
                                      {t(
                                        'investorProfile.AcceptanceCanNegAffectReturnsRisk'
                                      )}
                                    </FormLabel>
                                    <RadioGroup
                                      aria-label="acceptanceCanNegAffectReturnsRisk"
                                      name="acceptanceCanNegAffectReturnsRisk"
                                      value={
                                        values.acceptanceCanNegAffectReturnsRisk
                                      }
                                      onChange={handleChange}
                                    >
                                      <FormControlLabel
                                        disabled={isDisabled}
                                        value="true"
                                        control={<Radio />}
                                        label={t('Yes')}
                                      />
                                      <FormControlLabel
                                        disabled={isDisabled}
                                        value="false"
                                        control={<Radio />}
                                        label={t('No')}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Grid>
                              </>
                            ) : null}

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="summaryInvestmentPreferences"
                                label={t(
                                  'investorProfile.SummaryInvestmentPreferences'
                                )}
                                value={
                                  values.summaryInvestmentPreferences || ''
                                }
                                error={Boolean(
                                  touched.summaryInvestmentPreferences &&
                                    errors.summaryInvestmentPreferences
                                )}
                                fullWidth
                                multiline
                                minRows={4}
                                helperText={
                                  touched.summaryInvestmentPreferences &&
                                  errors.summaryInvestmentPreferences
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Typography variant="h6" gutterBottom>
                                {t('investorProfile.RecommendedAllocation')}
                              </Typography>
                            </Grid>
                            {/* coeli_allocationsassetshighliquidityDec */}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="allocationsAssetsHighLiquidityDec"
                                label={t(
                                  'investorProfile.AllocationsAssetsHighLiquidityDec'
                                )}
                                value={
                                  values.allocationsAssetsHighLiquidityDec || ''
                                }
                                error={Boolean(
                                  touched.allocationsAssetsHighLiquidityDec &&
                                    errors.allocationsAssetsHighLiquidityDec
                                )}
                                fullWidth
                                helperText={
                                  touched.allocationsAssetsHighLiquidityDec &&
                                  errors.allocationsAssetsHighLiquidityDec
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            {/* coeli_allocationsassetslimitedliquidityDec */}
                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="allocationsAssetsLimitedLiquidityDec"
                                label={t(
                                  'investorProfile.AllocationsAssetsLimitedLiquidityDec'
                                )}
                                value={
                                  values.allocationsAssetsLimitedLiquidityDec ||
                                  ''
                                }
                                error={Boolean(
                                  touched.allocationsAssetsLimitedLiquidityDec &&
                                    errors.allocationsAssetsLimitedLiquidityDec
                                )}
                                fullWidth
                                helperText={
                                  touched.allocationsAssetsLimitedLiquidityDec &&
                                  errors.allocationsAssetsLimitedLiquidityDec
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="recommendationAmountInterestFunds"
                                label={t(
                                  'investorProfile.RecommendationAmountInterestFunds'
                                )}
                                value={
                                  values.recommendationAmountInterestFunds || ''
                                }
                                error={Boolean(
                                  touched.recommendationAmountInterestFunds &&
                                    errors.recommendationAmountInterestFunds
                                )}
                                fullWidth
                                helperText={
                                  touched.recommendationAmountInterestFunds &&
                                  errors.recommendationAmountInterestFunds
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="recommendationAmoListEquityEquityF"
                                label={t(
                                  'investorProfile.RecommendationAmountListEquity'
                                )}
                                value={
                                  values.recommendationAmoListEquityEquityF ||
                                  ''
                                }
                                error={Boolean(
                                  touched.recommendationAmoListEquityEquityF &&
                                    errors.recommendationAmoListEquityEquityF
                                )}
                                fullWidth
                                helperText={
                                  touched.recommendationAmoListEquityEquityF &&
                                  errors.recommendationAmoListEquityEquityF
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="recommendationAmoAlterinvestHedge"
                                label={t(
                                  'investorProfile.RecommendationAmountAlternativeHedge'
                                )}
                                value={
                                  values.recommendationAmoAlterinvestHedge || ''
                                }
                                error={Boolean(
                                  touched.recommendationAmoAlterinvestHedge &&
                                    errors.recommendationAmoAlterinvestHedge
                                )}
                                fullWidth
                                helperText={
                                  touched.recommendationAmoAlterinvestHedge &&
                                  errors.recommendationAmoAlterinvestHedge
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="recommendationamoPrivEquityFund"
                                label={t(
                                  'investorProfile.RecommendationamoPrivateEquityFund'
                                )}
                                value={
                                  values.recommendationamoPrivEquityFund || ''
                                }
                                error={Boolean(
                                  touched.recommendationamoPrivEquityFund &&
                                    errors.recommendationamoPrivEquityFund
                                )}
                                fullWidth
                                helperText={
                                  touched.recommendationamoPrivEquityFund &&
                                  errors.recommendationamoPrivEquityFund
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="recommendationAmoRealestateFund"
                                label={t(
                                  'investorProfile.RecommendationAmountRealestateFund'
                                )}
                                value={
                                  values.recommendationAmoRealestateFund || ''
                                }
                                error={Boolean(
                                  touched.recommendationAmoRealestateFund &&
                                    errors.recommendationAmoRealestateFund
                                )}
                                fullWidth
                                helperText={
                                  touched.recommendationAmoRealestateFund &&
                                  errors.recommendationAmoRealestateFund
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="recommendationAmoListUnlinvesCase"
                                label={t(
                                  'investorProfile.RecommendationAmountListUnlistedCase'
                                )}
                                value={
                                  values.recommendationAmoListUnlinvesCase || ''
                                }
                                error={Boolean(
                                  touched.recommendationAmoListUnlinvesCase &&
                                    errors.recommendationAmoListUnlinvesCase
                                )}
                                fullWidth
                                helperText={
                                  touched.recommendationAmoListUnlinvesCase &&
                                  errors.recommendationAmoListUnlinvesCase
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="allowedDriftOnOverallRecommendedAllocation"
                                label={t(
                                  'investorProfile.AllowedDriftOnOverallRecommendedAllocation'
                                )}
                                value={
                                  values.allowedDriftOnOverallRecommendedAllocation ||
                                  ''
                                }
                                error={Boolean(
                                  touched.allowedDriftOnOverallRecommendedAllocation &&
                                    errors.allowedDriftOnOverallRecommendedAllocation
                                )}
                                fullWidth
                                helperText={
                                  touched.allowedDriftOnOverallRecommendedAllocation &&
                                  errors.allowedDriftOnOverallRecommendedAllocation
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <Field
                                name="advisorRecomRiskLevelforCustomer"
                                component={RadioGroupFormField}
                                options={advisorRecomRiskLevelforCustomer}
                                isDisabled={isDisabled}
                                label={t(
                                  'investorProfile.AdvisorRecomRiskLevelforCustomer'
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} mt={4}>
                              <TextField
                                name="summaryofOverallManagementStrategy"
                                label={t(
                                  'investorProfile.SummaryofOverallManagementStrategy'
                                )}
                                value={
                                  values.summaryofOverallManagementStrategy ||
                                  ''
                                }
                                error={Boolean(
                                  touched.summaryofOverallManagementStrategy &&
                                    errors.summaryofOverallManagementStrategy
                                )}
                                fullWidth
                                multiline
                                helperText={
                                  touched.summaryofOverallManagementStrategy &&
                                  errors.summaryofOverallManagementStrategy
                                }
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                              />
                            </Grid>
                          </>
                        ) : null}
                      </Grid>
                      {isConfirmed ? null : (
                        <Grid container mt={4}>
                          <Grid item>
                            <FormDialogConfirm
                              handleDataChanged={setDataChanged}
                              handleIsFetch={setIsFetched}
                            ></FormDialogConfirm>
                          </Grid>
                          <Grid item>
                            <FormDialogReject
                              handleDataChanged={setDataChanged}
                              handleIsFetch={setIsFetched}
                            ></FormDialogReject>
                          </Grid>
                        </Grid>
                      )}
                    </form>
                  ) : (
                    <Typography>{t('investorProfile.NoInfo')}</Typography>
                  )}
                </CardContent>
              </Card>
            )}
          </Formik>
        ) : (
          <div>
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
            <Box display="flex" justifyContent="center" my={6}>
              <Typography> {t('LongLoading')}</Typography>
            </Box>
          </div>
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
      <BaseForm />
    </React.Fragment>
  );
}

export default InvestorProfileUserForm;
