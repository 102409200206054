import React from 'react';
import * as Icon from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { AxiosInstance } from 'axios';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FaFilteredSecurityDto } from '../models/dto/faSecurityDto';
import { getSecurityDetailLink } from '../util/common';

export enum SecurityInfoSize {
  Small,
  Medium,
  Large,
}

export interface ISecurityInfoProps extends WithTranslation {
  isinCode?: string;
  opportunityId?: string;
  api: AxiosInstance;
  size: SecurityInfoSize;
  unlisted: boolean;
}

export interface ISecurityInfoState {
  url1?: string;
  url2?: string;
  allocation?: any;
  securityType?: string;
  detailsUrl?: string;
}

class SecurityDetails extends React.Component<
  ISecurityInfoProps,
  ISecurityInfoState
> {
  constructor(props: ISecurityInfoProps) {
    super(props);
    this.state = {
      detailsUrl: '',
      url1: '',
      url2: '',
    };
  }

  public componentDidMount = (): void => {
    if (this.props.unlisted != true) {
      this.props.api
        .get<FaFilteredSecurityDto>(
          `/api/fasecurity/GetSecurityDetails/${this.props.isinCode}`
        )
        .then(
          (res) => {
            let data = res.data;

            this.setState({
              url1: data.url,
              url2: data.url2,
              detailsUrl: getSecurityDetailLink(data),
            });
          },
          (err) => {}
        );
    }
  };

  public handleClickDownload = (id: string | undefined) => {
    //setIsFetched(false);
    if (id != undefined) {
      this.props.api
        .get(`api/OtherDocuments/getfile/${id}`, {
          method: 'GET',
        })
        .then(
          (res) => {
            const url = res.data.url;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', res.data.fileName);
            document.body.appendChild(link);
            //    setIsFetched(true);
            link.click();
          },
          (err) => {
            alert(err.Message);
            //  setIsFetched(true);
          }
        );
    }
  };

  render(): JSX.Element {
    return (
      <>
        {this.props.unlisted != true ? (
          <>
            <IconButton
              aria-label={this.props.t('tradeOrders.Details')}
              title={this.props.t('tradeOrders.Details')}
              href={this.state.detailsUrl!}
              className={'securityDetails-' + SecurityInfoSize[this.props.size]}
              target="_blank"
            >
              <Icon.InsertChart />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label={this.props.t('tradeOrders.BaseFacts')}
              title={this.props.t('tradeOrders.BaseFacts')}
              href={this.state.url1!}
              className={'securityDetails-' + SecurityInfoSize[this.props.size]}
              target="_blank"
            >
              <Icon.FindInPage />
            </IconButton>
            <IconButton
              color="primary"
              aria-label={this.props.t('tradeOrders.Sustainability')}
              title={this.props.t('tradeOrders.Sustainability')}
              href={this.state.url2!}
              className={'securityDetails-' + SecurityInfoSize[this.props.size]}
              target="_blank"
            >
              <Icon.Article />
            </IconButton>
          </>
        ) : (
          <>
            <Button
              sx={{ ml: 2 }}
              variant="outlined"
              color="primary"
              onClick={() => this.handleClickDownload(this.props.opportunityId)}
              className={'downloadBtn-' + SecurityInfoSize[this.props.size]}
              startIcon={<InsertDriveFileIcon />}
            >
              {this.props.t('Download')} {this.props.t('Document')}
            </Button>
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(SecurityDetails);
