import { Box, CircularProgress, Typography } from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AllocationType, InfoTableWidgetType } from '../../Enums';
import { useApiClient } from '../../hooks/apiClient';
import { FaPortfolioAllocationsDto } from '../../models/dto/faPortfolioAllocations';
import { useAppSelector } from '../../redux/hooks';
import { getActiveUser } from '../../redux/stateFuncs';
import InfoTableWidget, { IInfoData } from './InfoTableWidget';

export interface PortfolioIdsRequest {
  portfolioIds: string[];
}

export default function Allocations() {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const portfolios = useAppSelector((state) => state.portfolios);
  const [allocationData, setAllocationData] =
    useState<FaPortfolioAllocationsDto>();

  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  var activeUser = getActiveUser(users);
  const { t } = useTranslation();

  useEffect(() => {
    setIsLoading(true);
    const data = {
      portfolioIds: portfolios.filter((p) => p.show == true).map((p) => p.id),
    } as PortfolioIdsRequest;
    api
      .post<FaPortfolioAllocationsDto>(
        'api/FaSecurity/GetPortfolioAllocations',
        data
      )
      .then((res) => {
        var data: FaPortfolioAllocationsDto = res.data;
        // Sort by share desc
        data.allocations = data.allocations.sort((d1, d2) => {
          return d1.share > d2.share ? -1 : 1;
        });
        setAllocationData(data);
        setIsLoading(false);
        setError(null);
      })
      .catch((err) => {
        console.log(err);
        setError('Error loading portfolio allocations');
      });
  }, [portfolios]);

  return (
    <>
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Grid container padding={1}>
                <Grid
                  size={{ md: 12, lg: 6 }}
                  padding={1}
                  minWidth={350}
                  maxWidth={656}
                >
                  <Box marginBottom={2}>
                    <InfoTableWidget
                      title={t('investments.Allocation')}
                      type={InfoTableWidgetType.Percentage}
                      data={allocationData?.allocations
                        .filter(
                          (f) =>
                            f.allocationType == AllocationType.Husvy &&
                            f.share > 0
                        )
                        .map(
                          (ad) =>
                            ({
                              key: ad.allocationName,
                              value: ad.share * 100,
                            } as IInfoData)
                        )}
                      showPieChart={true}
                      showValues={true}
                    ></InfoTableWidget>
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </>
      )}
    </>
  );
}
