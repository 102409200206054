import React, { useEffect, useState } from 'react';

import {
  Button,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  ListItemText,
  Divider,
  Typography,
  List,
  ListItem,
  CircularProgress,
  DialogContentText,
  TextField,
} from '@mui/material';

import { IApproveData } from '../../models/approveData';
import { IInitSignResponse } from '../../models/initInsigniaResponse';
import {
  OpportunityState,
  Opportunity_statuscode,
  ToDoEnum,
} from '../../Enums';

import { useApiClient } from '../../hooks/apiClient';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { getActiveUser, getMainUser } from '../../redux/stateFuncs';
import { IOtherDocument } from '../../models/otherDocument';
import { useTranslation } from 'react-i18next';
import { setToDoComplete } from '../../redux/slices/todo';

function OtherDocuments(props: any) {
  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  var isChecking = false;

  const isToDo: boolean | undefined = props.isToDo;
  const activeUserCanSign = getActiveUser(users)?.canSign;
  const mainUserId = getMainUser(users)?.id;
  const mainUserSSN = getMainUser(users)?.socialSecurityNumber;

  const handleCloseError = () => {
    setApiError({ ...apiError, isOpen: false });
  };

  const handleClickDownload = (id: string) => {
    setIsFetched(false);
    api
      .get(`api/OtherDocuments/getfile/${id}`, {
        method: 'GET',
      })
      .then(
        (res) => {
          const url = res.data.url;
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', res.data.fileName);
          document.body.appendChild(link);
          setIsFetched(true);
          link.click();
        },
        (err) => {
          alert(err.Message);
          setIsFetched(true);
        }
      );
  };
  const handleClickSign = (id: string) => {
    setIsQrCodeFetched(false);

    var activeUser = getActiveUser(users);
    var activeEntityId = activeUser?.id;
    var entityType = activeUser?.isCompany == true ? 'account' : 'contact';

    let intervalId: number | undefined;
    let counter = 0;
    let initSigningResponse: IInitSignResponse;

    api
      .post('api/Signing/InitSigningOtherDocuments', {
        socialSecurityNumber: mainUserSSN,
        time: counter,
        opportunityId: id,
        orderRef: '',
      })
      .then((res) => {
        initSigningResponse = res.data as IInitSignResponse;
        setInitSignResponse(initSigningResponse);
        setIsQrCodeFetched(true);
      })
      .then(() => {
        intervalId = window.setInterval(() => {
          if (counter > 20) {
            window.clearInterval(intervalId);
            setIsQrCodeFetched(false);
            api
              .post(`api/Signing/CancelSign/${initSigningResponse.orderRef}`)
              .then((res) => {
                setIsProgress(false);
              });
            setApiError({
              ...apiError,
              isOpen: true,
              Error: 'Signing Error',
              InnerError:
                'The allowed time for signing the document has been exceeded. To repeat the operation, click on the confirm button.',
            });
          } else {
            if (!isChecking) {
              isChecking = true;
              counter++;

              initSigningResponse.time = counter;
              initSigningResponse.approverId = mainUserId;
              initSigningResponse.entityId = id;
              initSigningResponse.ownerId = activeEntityId;
              initSigningResponse.customerLogicalName = entityType;
              initSigningResponse.entityLogicalName = 'opportunity';
              initSigningResponse.socialSecurityNumber = mainUserSSN;
              initSigningResponse.status = '';

              api
                .post(
                  `api/Signing/GetSigningAndApproveOtherDocument`,
                  initSigningResponse
                )
                .then(
                  (res) => {
                    initSigningResponse = res.data as IInitSignResponse;
                    setInitSignResponse(initSigningResponse);
                    if (initSigningResponse.status === 'complete') {
                      const newOtherDocuments = otherDocuments.map((p) => {
                        if (p.id == id) {
                          p.stateCode = OpportunityState.Won;
                          p.statusCode = Opportunity_statuscode.Won;
                        }
                        return p;
                      });
                      setOtherDocuments(newOtherDocuments);
                      window.clearInterval(intervalId);
                      setIsQrCodeFetched(false);
                      setIsSigning(false);

                      // Check if this is a todo popup and if all done, mark to do as complete
                      if (
                        isToDo &&
                        !newOtherDocuments.some(
                          (s) =>
                            s.statusCode === Opportunity_statuscode.UnderRevision
                        )
                      ) {
                        dispatch(setToDoComplete(ToDoEnum.OtherDocuments));
                      }
                    }

                    isChecking = false;
                  },
                  (err) => {
                    isChecking = false;
                    api.post(
                      `api/Signing/CancelSign/${initSigningResponse.orderRef}`
                    );
                    window.clearInterval(intervalId);
                    setIsQrCodeFetched(false);
                    setIsSigning(false);
                    if (
                      err.response.data.additionalDetails.message !=
                      'InvalidParameters: No such order'
                    )
                      setApiError({
                        ...apiError,
                        isOpen: true,
                        Error: 'Signing Error',
                        InnerError: err.response.data.additionalDetails.message,
                      });
                  }
                );
            }
          }
        }, 2000);
      });
  };

  function FormDialogReject(props: any) {
    const [open, setOpen] = React.useState(false);
    const [rejectComment, setRejectComment] = React.useState<string>('');

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClickClose = () => {
      setOpen(false);
    };

    const handleClickReject = (id: string) => {
      props.setIsProgress(true);

      var activeUser = getActiveUser(users);
      var ownerId = activeUser?.id;
      var ownerEntityLogicalName =
        activeUser?.isCompany == true ? 'account' : 'contact';

      const approveData: IApproveData = {
        approverId: mainUserId,
        ownerId: ownerId,
        entityId: id,
        ownerEntityLogicalName: ownerEntityLogicalName,
        rejectComment: rejectComment,
      };

      api.post('api/otherdocuments/reject', { ...approveData }).then(
        (res) => {
          const newOtherDocuments = (
            props.otherDocuments as IOtherDocument[]
          ).map((o) => {
            if (o.id == id) {
              o.stateCode = OpportunityState.Lost;
              o.statusCode = Opportunity_statuscode.Canceled;
            }

            return o;
          });
          props.setOtherDocuments(newOtherDocuments);
          props.setIsProgress(false);

          // Check if this is a todo popup and if all done, mark to do as complete
          if (
            isToDo &&
            !newOtherDocuments.some(
              (s) => s.stateCode === Opportunity_statuscode.UnderRevision
            )
          ) {
            dispatch(setToDoComplete(ToDoEnum.OtherDocuments));
          }
        },
        (err) => {
          console.error(err);
          props.setIsProgress(true);
          props.setApiError({
            ...apiError,
            isOpen: true,
            Error: 'Signing Error',
            InnerError: err.response.data.additionalDetails.message,
          });
        }
      );
    };

    return (
      <>
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          sx={{ ml: 2 }}
        >
          {t('Reject')}
        </Button>
        <Dialog
          maxWidth="sm"
          fullWidth={true}
          open={open}
          onClose={handleClickClose}
        >
          <DialogTitle> {t('LegalNote')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('otherDocuments.dialog.RejectText')}
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="comment"
              label={t('RejectReason')}
              fullWidth
              multiline
              minRows={4}
              variant="outlined"
              required={true}
              value={rejectComment}
              onChange={(e) => setRejectComment(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              disabled={rejectComment === '' ? true : false}
              onClick={() => handleClickReject(props.id)}
            >
              {t('Reject')}
            </Button>
            <Button onClick={() => handleClickClose()}> {t('Cancel')}</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  const [isFetched, setIsFetched] = useState(false);
  const [otherDocuments, setOtherDocuments] = useState<IOtherDocument[]>([]);
  const [isProgress, setIsProgress] = useState(false);
  const [initSignResponse, setInitSignResponse] = useState<IInitSignResponse>();
  const [isQrCodeFetched, setIsQrCodeFetched] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [apiError, setApiError] = React.useState({
    isOpen: false,
    Error: '',
    InnerError: '',
  });

  useEffect(() => {
    setIsFetched(false);
    let activeUser = getActiveUser(users);

    api.get(`api/otherdocuments/${activeUser?.id}`).then((res) => {
      setOtherDocuments(res.data as IOtherDocument[]);
      setIsFetched(true);
    });
  }, [users]);

  return (
    <React.Fragment>
      <Dialog
        open={apiError.isOpen}
        onClose={handleCloseError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{apiError.Error}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {apiError.InnerError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseError}>Ok</Button>
        </DialogActions>
      </Dialog>

      {isProgress || isSigning ? (
        <Box display="flex" justifyContent="center" my={6}>
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={6}>
          <Grid item xs={12} md={12}>
            {isQrCodeFetched ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div>
                  <img
                    style={{ maxWidth: '300px' }}
                    alt="Embedded QR Code"
                    src={
                      'data:image/jpeg;base64,' +
                      initSignResponse?.qrCodeAsBase64
                    }
                  />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        window.location.href = `bankid:///?autostarttoken=${initSignResponse?.autoStartToken}&redirect=null`;
                        setIsQrCodeFetched(false);
                        setIsFetched(false);
                        setIsSigning(true);
                      }}
                    >
                      {t('signing.ConfirmSameDevice')}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <>
                {isToDo ? null : (
                  <>
                    {isFetched ? (
                      <>
                        <Typography variant="h6" gutterBottom display="inline">
                          {t('Signed')}
                        </Typography>

                        <List>

                          {otherDocuments.map((c) => {
                            if (c.statusCode === Opportunity_statuscode.Won) {
                              return (
                                <ListItem key={c.id}>
                                  <ListItemText>
                                    {c.name}{' '}
                                    <Typography display="inline" variant="body2">
                                      (Ansvarig hos Coeli: <b>{c.ownerName}</b>)
                                    </Typography>
                                  </ListItemText>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => handleClickDownload(c.id)}
                                  >
                                    {t('Download')} {t('Document')}
                                  </Button>
                                  <Divider />
                                </ListItem>
                              );
                            } else return <></>;
                          })
                          }
                        </List>
                      </>
                    ) : (
                      <></>
                    )
                    }
                  </>
                )}
              </>
            )}
          </Grid>
          {isQrCodeFetched ? (
            ''
          ) : (
            <>
              <Grid item xs={12} md={12}>
                {isFetched ? (
                  <>
                    <Typography variant="h6" gutterBottom display="inline">
                      {t('ForApproval')}
                    </Typography>
                    <List>
                      {otherDocuments.map((c) => {
                        if (c.statusCode === Opportunity_statuscode.UnderRevision) {
                          return (
                            <ListItem key={c.id}>
                              <ListItemText>
                                {c.name}{' '}
                                <Typography display="inline" variant="body2">
                                  (Ansvarig hos Coeli: <b>{c.ownerName}</b>)
                                </Typography>
                              </ListItemText>
                              <div>
                                <Button
                                  sx={{ ml: 2 }}
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => handleClickDownload(c.id)}
                                >
                                  {t('Download')} {t('Document')}
                                </Button>
                                {activeUserCanSign ? (
                                  <>
                                    <Button
                                      sx={{ ml: 2 }}
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => handleClickSign(c.id)}
                                    >
                                      {t('Confirm')}
                                    </Button>
                                    <FormDialogReject
                                      id={c.id}
                                      otherDocuments={otherDocuments}
                                      setOtherDocuments={setOtherDocuments}
                                      setIsProgress={setIsProgress}
                                      setApiError={setApiError}
                                    />
                                  </>
                                ) : null}
                              </div>
                              <Divider />
                            </ListItem>
                          );
                        } else return null;
                      })}
                    </List>
                  </>
                ) : (<>
                  <Box display="flex" justifyContent="center" my={6}>
                    <CircularProgress />
                  </Box>
                </>)}
              </Grid>

            </>
          )}
        </Grid>
      )}
    </React.Fragment>
  );
}
export default OtherDocuments;
