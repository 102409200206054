import { TradeOrderOperationEnum } from '../Enums';

export function translateCRMString(value: string, t: any): string {
  switch (value) {
    /* Document types */
    case 'Kyc':
      return t('kyc.Kyc');
    case 'Investor Profile':
      return t('InvestorProfile');
    case 'Other Document':
      return t('OtherDocument');
    case 'Trade Order':
      return t('TradeOrder');
    case 'Portfolio Agreement':
      return t('PortfolioAgreement');
    case 'New Portfolio Agreement':
      return t('NewPortfolioAgreement');
    case 'Unlisted Trade Order':
      return t('UnlistedTradeOrder');

    /* Status */
    case 'Approved':
      return t('Approved');
    case 'Rejected':
      return t('Rejected');
  }
  return value;
}

export function getOrderTypeNameString(value: number): string {
  switch (value) {
    /* Order types */
    case TradeOrderOperationEnum.BuyForCashBFC:
      return 'tradeOrders.BuyForCashBFC';
    case TradeOrderOperationEnum.SellForCashSFC:
      return 'tradeOrders.SellForCashSFC';
    case TradeOrderOperationEnum.BuyUnitsB:
      return 'tradeOrders.BuyUnitsB';
    case TradeOrderOperationEnum.SellUnitsS:
      return 'tradeOrders.SellUnitsS';
    default:
      return '';
  }
}

// export function getOrderTypeName(value: number, t: any): string {
//   switch (value) {
//     /* Order types */
//     case TradeOrderOperationEnum.BuyForCashBFC:
//       return t('tradeOrders.BuyForCashBFC');
//     case TradeOrderOperationEnum.SellForCashSFC:
//       return t('tradeOrders.SellForCashSFC');
//     case TradeOrderOperationEnum.BuyUnitsB:
//       return t('tradeOrders.BuyUnitsB');
//     case TradeOrderOperationEnum.SellUnitsS:
//       return t('tradeOrders.SellUnitsS');
//   }
//   return '';
// }

export function getTransactionType(value: string, t: any): string {
  switch (value) {
    case 'Buy':
      return t('transactions.Buy');
    case 'Buy for Cash':
      return t('transactions.Buy');
    case 'Sell':
      return t('transactions.Sell');
    case 'Sell for Cash':
      return t('transactions.Sell');
    case 'Deposit':
      return t('transactions.Deposit');
    case 'Dividend':
      return t('transactions.Dividend');
    default:
      return value;
  } 
}
