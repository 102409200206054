import {
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { Box } from '@mui/system';
import { ReactNode } from 'react';
import { useApiClient } from '../hooks/apiClient';
import React from 'react';
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  return (
    <>
      <Box className="footer" sx={{ display: { xs: 'none', md: 'block' } }}>
        {FooterLinks()}
      </Box>
      <Box
        className="footer-mobile"
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        {FooterLinks()}
      </Box>
    </>
  );
};

// Cookie Policy
function CookieDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState('');
  const { t } = useTranslation();

  const handleClickOpen = () => {
    // api.get('api/GetCookiePolicy').then((res) => {
    //   setContent(res.data);
    // });

    setContent(
      'Cookies ("kakor") består av små textfiler. Dessa innehåller data som lagras på din enhet. För att kunna placera vissa typer av cookies behöver vi inhämta ditt samtycke. För att läsa mer om vilka cookies vi använder och lagringstid, klicka här för att komma till våra cookieinställningar.'
    );

    setOpen(true);
  };

  const handleClose = (isSubmit: boolean) => {
    setOpen(false);
  };

  return (
    <>
      <Link
        sx={{ display: 'inline' }}
        component="button"
        variant="body2"
        onClick={() => {
          handleClickOpen();
        }}
      >
        Cookie Policy
      </Link>
      <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>Cookie Policy</DialogTitle>
        <DialogContent>
          <Typography variant="body2">{parse(content)}</Typography>
          <br />
          <br />
          <Box
            dangerouslySetInnerHTML={{
              __html:
                '<a class="cookie-link" href="javascript: Cookiebot.renew()">' +
                t('RenewOrChangeCookieConsent') +
                ' >>' +
                '</a>',
            }}
          ></Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

// User Policy
function UserPolicyDialog(props: any) {
  const [open, setOpen] = React.useState(false);
  const [content, setContent] = React.useState('');
  const { t } = useTranslation();

  var api = useApiClient();

  const handleClickOpen = () => {
    // api.get('api/GetUserPolicy').then((res) => {
    //   setContent(res.data);
    // });

    setOpen(true);
  };

  const handleClose = (isSubmit: boolean) => {
    setOpen(false);
  };

  return (
    <>
      <Link
        sx={{ display: 'inline' }}
        component="button"
        variant="body2"
        onClick={() => {
          handleClickOpen();
        }}
      >
        {t('TermsOfUse')}
      </Link>
      <Dialog maxWidth="sm" fullWidth={true} open={open} onClose={handleClose}>
        <DialogTitle>{t('TermsOfUse')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('termsOfUseLines.Line1')}<br /><br />            
            {t('termsOfUseLines.Line2')}<br /><br />
            {t('termsOfUseLines.Line3')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(true)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function FooterLinks(): ReactNode {
  return (
    <Box sx={{ padding: '4px;' }}>
      <Typography sx={{ display: 'inline' }}>
        Copyright 2024 Coeli -{' '}
      </Typography>
      <CookieDialog></CookieDialog>
      <Typography sx={{ display: 'inline' }}> - </Typography>

      <UserPolicyDialog></UserPolicyDialog>
    </Box>
  );
}

export default Footer;
