import { Box, CircularProgress, Tooltip, Typography } from '@mui/material';
import { Grid2 as Grid } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { FaPortfolioOverviewDto } from '../../models/dto/faPortfolioOverviewDto';
import { useAppSelector } from '../../redux/hooks';
import { getActiveUser } from '../../redux/stateFuncs';
import { useApiClient } from '../../hooks/apiClient';
import CurrencyField from '../formfields/CurrencyField';
import PercentageField from '../formfields/PercentageField';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { clarity } from 'react-microsoft-clarity';

interface portfolioDevelopment {
  name: string;
  shortName: string;
  ownerId: string;
  value: number;
  change: number;
  id: string;
}

export default function PortfolioOverview() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>();
  const [error, setError] = useState<string | null>(null);
  const [portfolioOverview, setPortfolioOverviews] = useState<
    portfolioDevelopment[]
  >([]);

  const api = useApiClient();
  const users = useAppSelector((state) => state.users);
  const portfolios = useAppSelector((state) => state.portfolios);
  const customers = useAppSelector((state) => state.customers);
  var activeUser = getActiveUser(users);

  var now = format(new Date(), 'yyyy-MM-dd');

  useEffect(() => {
    setIsLoading(true);
    const postData = {
      socialSecurityNumber: activeUser?.socialSecurityNumber,
      fromDate: '2024-01-01',
      toDate: now,
    };
    api
      .post<FaPortfolioOverviewDto[]>(
        'api/FaSecurity/GetPortfolioOverview',
        postData
      )
      .then((res) => {
        portfolioOverview.splice(0);
        res.data.map((i) => {
          portfolioOverview.push({
            id: i.id,
            ownerId: i.ownerId,
            name: i.name,
            shortName: i.shortName,
            value: i.positionMarketValue + i.cashBalance,
            change: i.twr,
          } as portfolioDevelopment);
          return '';
        });
        setIsLoading(false);
        setError(null);
      })
      .catch((err) => {
        setError('Error loading portfolio overview');
      });
  }, [activeUser]);

  // useEffect(() => {
  //   clarity.setTag('investment', 'overview');
  // }, []);

  return (
    <Box className="portfolio-overview">
      {error ? (
        <>
          <Box display="flex" justifyContent="center" my={6}>
            <Typography color="error.main">{error}</Typography>
          </Box>
        </>
      ) : (
        <>
          {isLoading ? (
            <Box display="flex" justifyContent="center" my={6}>
              <CircularProgress />
            </Box>
          ) : (
            <Grid
              container
              rowSpacing={1}
              sx={{
                '--Grid-borderWidth': '2px',
                '& > div': {
                  borderBottom: 'var(--Grid-borderWidth) solid',
                },
              }}
            >
              <Grid size={{ xs: 7 }}>
                <Box display="flex" justifyContent="flex-start">
                  <Typography className="text" textAlign={'left'}>
                    {t('Portfolio')}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={{ xs: 5 }}>
                <Box display="flex" justifyContent="flex-end">
                  <Typography className="text" textAlign={'right'}>
                    {t('Marketvalue')}
                    <br />
                    {t('ProgressThisYear')}
                  </Typography>
                </Box>
              </Grid>
              {portfolioOverview
                .filter((po) =>
                  portfolios
                    .filter((p) => p.show === true)
                    .map((p) => p.id)
                    .includes(po.id)
                )
                .map((p) => {
                  return (
                    <React.Fragment key={p.shortName}>
                      <Grid size={{ xs: 7 }}>
                        <Tooltip title={p.name}>
                          <Box>
                            <Typography className="text">
                              {p.shortName}
                            </Typography>
                            <Typography className="text">
                              {
                                customers.find((c) => c.contactId === p.ownerId)
                                  ?.name
                              }
                            </Typography>
                          </Box>
                        </Tooltip>
                      </Grid>
                      <Tooltip title={p.name}>
                        <Grid size={{ xs: 5 }} textAlign={'right'}>
                          <CurrencyField value={p.value} />
                          <PercentageField value={p.change} />
                        </Grid>
                      </Tooltip>
                    </React.Fragment>
                  );
                })}
              <Grid size={{ xs: 7 }}>
                <Tooltip title={t('Sum')}>
                  <Box>
                    <Typography className="boldtext">{t('Sum')}</Typography>
                    <Typography className="text"></Typography>
                  </Box>
                </Tooltip>
              </Grid>
              <Grid size={{ xs: 5 }} textAlign={'right'}>
                {portfolioOverview.length > 0 ? (
                  <CurrencyField
                    className="boldtext"
                    value={portfolioOverview
                      .filter((po) =>
                        portfolios
                          .filter((p) => p.show === true)
                          .map((p) => p.id)
                          .includes(po.id)
                      )
                      .map((c) => c.value)
                      .reduce((sum, cur) => sum + cur, 0)}
                  />
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Box>
  );
}
